import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Grid, TableCell, TableRow, Typography, Link} from '@mui/material';
import CustomerOrderDetail from './CustomerOrderDetail';
import CustomerOrderFCLDetail from './CustomerOrderFCLDetail';
import CustomerDepartureDetail from './CustomerDepartureDetail';
import { STATUS } from 'constants/global-constants';

const CustomerOrderRowInfo = ({ order, onClickRow }) => {
  const { shippingModeData } = useSelector(
    state => state.predefinedData,
  );

  const disableAnchor = {
    pointerEvents : 'none',
    cursor        : 'default',
    textDecoration: 'none'
  };
/*

    <TableRow>
      <TableRow sx={{ width: 100, pr: 0 }}>
        <Typography fontSize="0.8rem" variant='body1' component='span'>
          {shippingModeData?.find(mode => mode.id === order.shippingModeId)?.name || '-'} 
        </Typography>
      </TableRow>
      <TableRow sx={{ width: 100, pr: 0 }}>
        <Typography fontSize="0.8rem" variant='body1' component='span'>Shipment details</Typography>
        {order?.orderDetails?.length ? (
            order.orderDetails.map(detail => {
              return <CustomerOrderDetail detail={detail} />;
            })
          ) : (order.orderFCLDetails ? 
              <CustomerOrderFCLDetail detail={order.orderFCLDetails} />
          : (
            <Typography variant='h6' noWrap my={2}>
              Details are empty.
            </Typography>
          ))}
      </TableRow>
      <TableRow sx={{ width: 100, pr: 0 }}>
        <Typography fontSize="0.8rem" variant='body1' component='span'>
          {(order.orderStage[4].status === STATUS['4']?
            (order.orderStage[3].status === STATUS['4']?
              (order.orderStage[2].status === STATUS['4']?
                (order.orderStage[1].status === STATUS['4']?
                  (order.orderStage[0].status === STATUS['4']? 
                    "": "Order Confirmation"):
                    "Shipper"):
                    "Trucking"):
                    "W/H"):
                    "Departure")
          }
        </Typography>
      </TableRow>
      {order?.orderStage[4]?.departureStage[0] ? 
      <TableRow sx={{ width: 100, pr: 0 }}>
        <CustomerDepartureDetail detail={order.orderStage[4].departureStage[0]} status={order.status}/>
      </TableRow>
      :null}
    </TableRow>
*/

  return (
    <Fragment>
      <Grid container item xs={12} justifyContent='space-between' style={{border:'1px solid grey', margin:'5px 0px', padding:'0px'}}>
        <Link href="#" style={((order?.orderStage[4]?.departureStage[0]?.flightNumber && order?.orderStage[4]?.departureStage[0]?.etd) || (order?.orderStage[4]?.departureStage[0]?.imo && order?.orderStage[4]?.departureStage[0]?.master)) ? null : disableAnchor} onClick={() => onClickRow(order?.id, order?.shippingModeId ,
                            order?.orderStage[4]?.departureStage[0]?.flightNumber, order?.orderStage[4]?.departureStage[0]?.etd, order?.orderStage[4]?.departureStage[0]?.imo, order?.orderStage[4]?.departureStage[0]?.master)} underline='none'>
          <Typography style={{margin:'2px'}} fontSize="0.8rem" variant='body1' component='span'>{shippingModeData?.find(mode => mode.id === order.shippingModeId)?.name || '-'} </Typography>
          {order?.orderDetails?.length ? (
            order.orderDetails.map((detail, index) => {
                return <CustomerOrderDetail key={index} detail={detail} />;
            })
          ) : (order.orderFCLDetails ? 
              <CustomerOrderFCLDetail detail={order.orderFCLDetails} />
          : (
            <Typography variant='h6' noWrap my={2}>
              Details are empty.
            </Typography>
          ))}
          <Typography style={{margin:'2px'}} fontSize="0.8rem" variant='body1' component='span'>
          {(order.orderStage[4].status === STATUS['4']?
            (order.orderStage[3].status === STATUS['4']?
              (order.orderStage[2].status === STATUS['4']?
                (order.orderStage[1].status === STATUS['4']?
                  (order.orderStage[0].status === STATUS['4']? 
                    "": "Order Confirmation"):
                    "Shipper"):
                    "Trucking"):
                    "W/H"):
                    "Departure")
          }
          </Typography>
          {order?.orderStage[4]?.departureStage[0] ? 
            <CustomerDepartureDetail detail={order.orderStage[4].departureStage[0]} status={order.status}/>
            :null
          }
        </Link >
      </Grid>
    </Fragment>
  );
};

export default CustomerOrderRowInfo;
