import { useState, memo, useEffect } from 'react';

import StageButton from '../StageButton';
import PodDialog from './PodDialog';
import {StyledTableCell} from 'Utils';

const Pod = ({ loginUser, order, pageName }) => {
    const [customsDate, setCustomsDate] = useState(null);

    useEffect(() => {
        setCustomsDate(getCustomsDate());
    }, []);
    const getCustomsDate = () => {
        if (order?.astatus?.podDate) {
            if (order?.astatus?.podDate.includes(" 1970"))
                return null;
            else return order.astatus?.podDate;
        }
        if (order?.cstatus?.podDate) {
            if (order?.cstatus?.podDate.includes(" 1970"))
                return null;
            else return order.cstatus?.podDate;
        }
        if (order?.sinay?.arDate1) {
            if (order?.sinay?.arDate1.includes(" 1970"))
                return null;
            else return order.sinay?.arDate1;
        }
        if (order?.orderStage[4]?.departureStage[0]?.eta)
            return order.orderStage[4]?.departureStage[0]?.eta;
        return null;
    }
    const getGap = () => {
        if (pageName === "orderHistory" || !order.orderStage[4]?.departureStage[0]?.eta || !customsDate)
            return "";

        var delay = 0;
        var timeDiff = getDiff1();
        delay = timeDiff / 86400000 | 0;
        if (delay != 0) {
            if (delay > 99)
                delay = 99;
            if (delay < -99)
                delay = -99;
            return " (" + delay + ")";
        }
        return ""
    }
    const getDiff1 = () => {

        //var dt1 = getCustomsDate();
        //var date1 = new Date(dt1);
        //var date2 = new Date();
        var dt1 = order.orderStage[4]?.departureStage[0]?.eta;
        if (dt1) {
            var date1;
            if (!isNaN(dt1))
                date1 = new Date(Number(dt1));
            else
                date1 = new Date(dt1);
            date1.setHours(1);
            date1.setMinutes(0);
            date1.setSeconds(0);
            date1.setMilliseconds(0);
            var dt2 = getCustomsDate();
            if (dt2) {
                var date2;
                if (!isNaN(dt2))
                    date2 = new Date(Number(dt2));
                else
                    date2 = new Date(dt2);
                date2.setHours(1);
                date2.setMinutes(0);
                date2.setSeconds(0);
                date2.setMilliseconds(0);
                var timeDiff = date2.getTime() - date1.getTime();
                return timeDiff;
            }
        }
        return 0;
    }
    const getDiff = () => {
        
        var dt1 = getCustomsDate();
        var date1 = new Date(dt1);
        var date2 = new Date();
        var timeDiff = date2.getTime() - date1.getTime();
        return timeDiff;
    }

    const getStatus = () => {
        if (order.cstatus?.podExit)
            return "SUCCESS";
        if (order?.orderStatus[7]?.status === "SUCCESS")
            return "SUCCESS";
        if (order?.orderStatus[6]?.status === "SUCCESS")
            return "SUCCESS";
        if (order?.orderStatus[6]?.status === "NOT_STARTED")
            return "NOT_STARTED";

        var status = order?.orderStatus[6]?.status ? order?.orderStatus[6]?.status : "NOT_STARTED";
        var delay = 0;
        var timeDiff = getDiff();
        if (timeDiff > 0)
            delay = timeDiff / 86400000 | 0;
        if (delay > 0)
            return "FAILED";
        else
            return "IN_PROGRESS";
        return order?.orderStatus[6]?.status ? order.orderStatus[6].status : "NOT_STARTED";
    }

    const [openDialog, setOpenDialog] = useState(false);
    const onHandleOpenDialog = () => setOpenDialog(true);
    const onHandleCloseDialog = () => setOpenDialog(false);

  return (
      <StyledTableCell style={{ width: "5vw", p: 0 }}>
          {/*<StageButton stage={{ 'status': `${order?.orderStatus ? ((order?.orderStatus[6]?.status === "SUCCESS" || order?.orderStatus[6]?.status === "IN_PROGRESS") ? order.orderStatus[6]?.status : `${order?.orderStatus[4]?.status === "SUCCESS" ? "IN_PROGRESS" : "NOT_STARTED"}`) : null}`, 'notes': "" }} onHandleOpenDialog={onHandleOpenDialog} stageName='POD' loginUser={loginUser} />*/}
          <StageButton stage={{ 'status': `${getStatus()}`, 'notes': "" }} onHandleOpenDialog={onHandleOpenDialog} stageName={'ARR' + getGap()} loginUser={loginUser} />
          {openDialog && <PodDialog onHandleCancel={onHandleCloseDialog} loginUser={loginUser} order={order} openDialog={openDialog} setOpenDialog={setOpenDialog} />}
    </StyledTableCell>
  );
};

export default memo(Pod);
