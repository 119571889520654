import { useCallback, useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { TableHead, TableRow, Typography, Grid } from '@mui/material';
import { DateTimePicker, SelectField, Dialog, Tooltip } from 'style-guide';
import { REQUEST_ACTIONS, sendRequest, setOrderData } from 'utils/Communicator';
import { sendNewChatMsgNotification, sendNewOrderNotification } from 'utils/Notifications';
import { useDispatch, useSelector } from 'react-redux';
import { STAGES_OPTIONS, USA_STATES } from 'constants/global-constants';
import OrderSearch from '../OrderTable/OrderSearch';
import { getOrders, onCustChatListChange, onChatListChange, onOrdersCountChange, onChatCountChange, onChatCountTChange, onChatCountCChange, onChatCountCTChange, onUserSpecificOrdersCountChange, onPageChange, onUnConfirmedOrdersCountChange } from 'reducers/order-reducers/ordersSlicer';
import {
    onConsigneeIdChange,
    onCountryOfOriginIdChange,
    onShipperIdChange,
    onShippingModeIdChange,
    onSubModeIdChange,
    onUserLocalIdChange,
    onUserTracerChange,
    onUserCustomsChange,
    onUserAbroadIdChange,
    onIlReminderDateChange,
    onETAChange,
    onETDChange,
    OnStageSearchChange,
    onReminderDateChange,
    onOriginStateChange,
    resetOrderTableFilterSlice,
    onUrgentShipmentChange,
    setSearchText,
    setDataInterval,
    setChatInterval,
    setOrderUpdateNum,
} from 'reducers/order-reducers/orderTableFilterSlicer';
import { snackbarToggle } from 'reducers/snackbarSlicer';
import { addOrderFilter, addSearchFilter, StyledTableCell, advancedSearchSelectedStyle, advancedSearchDefaultStyle, usePrevious } from 'Utils';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import AdvancedSearchDialog from './AdvancedSearchDialog';

var orderTimeout = null;

var loginUser123 = null, pageName123 = null, selectedLocationIndex123 = null, customerId123 = null, urgentShipment123 = null, userLocalNumber123 = null, userAbroadNumber123 = null, consigneeId123 = null, shipperId123 = null, countryOfOriginId123 = null, originState123 = null, shippingModeId123 = null, subModeId123 = null, userLocalId123 = null, userTracer123 = null, userCustoms123 = null, userAbroadId123 = null, dueDate123 = null, search123 = null, searchText123 = null, lastUpdateDate123 = null, reminderDate123 = null, ilReminderDate123 = null, startReminderDate123 = null, endReminderDate123 = null, startIlReminderDate123 = null, endIlReminderDate123 = null, stageSearch123 = null, stageStateSearch123 = null, reminderDateAdv123 = null, ilReminderDateAdv123 = null, pageName123 = null, fileNumber123 = null, etaDate123 = null, etdDate123 = null;

const OrderTableHeader = ({ loginUser, setFilterSpinner, pageName,  }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const onHandleChange = (event, action) => dispatch(action(event.target.value));
    const [openAdvSearchDialog, setOpenAdvSearchDialog] = useState(false);
    const onAdvSearchDialogHandleOpen = () => setOpenAdvSearchDialog(true);
    const {
        userLocalNumber,
        userAbroadNumber,
        consigneeId,
        customerId,
        shipperId,
        countryOfOriginId, originState, fileNumber,
        shippingModeId,
        subModeId,
        userLocalId,
        userTracer,
        userCustoms,
        urgentShipment,
        userAbroadId,
        dueDate,
        lastUpdateDate,
        ilReminderDate,
        etaDate,
        etdDate,
        reminderDate,
        selectedLocationIndex,
        search,
        startReminderDate,
        endReminderDate,
        startIlReminderDate,
        endIlReminderDate,
        stageSearch,
        stageStateSearch, searchText, chatInterval, dataInterval, orderUpdateNum,
        reminderDateAdv, ilReminderDateAdv, } = useSelector(state => state.orderTableFilter);
    const { shipperNameOptions } = useSelector(state => state.shippers);
    const { consigneeNameOptions } = useSelector(state => state.consignees);
    const { userILNames, userNLNames, userUSNames, shippingModeData, subModeData, countryOfOriginData } = useSelector(
        state => state.predefinedData,
    );
    const [userILImporterNames, setUserILImporterNames] = useState([]);
    const [userILTracerNames, setUserILTracerNames] = useState([]);
    const [userILCustomsNames, setUserILCustomsNames] = useState([]);
    const { page, size, chatCount, userSpecificOrdersCount, unConfirmedOrdersCount } = useSelector(state => state.orders);
    const prevPage = usePrevious({ page });
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const failedCallback1 = useCallback(message => {
        dispatch(snackbarToggle({ type: 'error', message }))
        dispatch(setOrderUpdateNum(false));
    }, [dispatch]);
    const dataRefreshInterval = useRef(null);
    const chatRefreshInterval = useRef(null);
    const setSearchText1 = (data) => {
        dispatch(setSearchText(data));
    }
    useEffect(() => {
        if (!location?.state?.search && !orderUpdateNum) {
            setFilterSpinner(true);
            dispatch(setOrderUpdateNum(true));
            sendRequest(`/api/order/count?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, fileNumber, etaDate, etdDate)}`, REQUEST_ACTIONS.GET, '', {
                successCallback: response => {
                    dispatch(onOrdersCountChange(response?.ordersCount)); sendNewChatMsgNotification(loginUser, dispatch, chatCount, response?.unreadChatCount);
                    dispatch(onChatCountTChange(response?.unreadChatTracerCount));
                    dispatch(onChatCountCTChange(response?.unreadChatCTCount));
                    dispatch(onChatCountCChange(response?.unreadChatCustomsCount)); dispatch(onChatCountChange(response?.unreadChatCount)); sendNewOrderNotification(loginUser, dispatch, userSpecificOrdersCount, response?.userSpecificOrdersCount); dispatch(onUserSpecificOrdersCountChange(response?.userSpecificOrdersCount)); dispatch(onUnConfirmedOrdersCountChange(response?.unConfirmedOrdersCount));
                    sendRequest(
                        `/api/order?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, fileNumber, etaDate, etdDate)}&page=${prevPage?.page == page && addSearchFilter(customerId, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, fileNumber, etaDate, etdDate)?.length ? 0 : page}&size=${size}`,
                        REQUEST_ACTIONS.GET,
                        '',
                        {
                            successCallback: response => {
                                dispatch(setOrderUpdateNum(false));
                                setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
                                setFilterSpinner(false);
                                if (prevPage?.page == page && addSearchFilter(customerId, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, fileNumber, etaDate, etdDate)) {
                                    dispatch(onPageChange(0));
                                }
                            },
                            failedCallback1,
                        },
                    );
                },
                failedCallback1,
            });
            // filtered orders get

        } else {
            window.history.replaceState({}, '');
            var str = "";
            if (location?.state?.searchText) {
                dispatch(setSearchText(location?.state?.searchText));
                str = location?.state?.searchText;
            }
            else if (location?.state?.search) {
                dispatch(setSearchText(location?.state?.search));
                str = location?.state?.search;
            }
            if(str)
            sendRequest(`/api/order/count?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, str, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, fileNumber, etaDate, etdDate)}`, REQUEST_ACTIONS.GET, '', {
                successCallback: response => {
                    dispatch(onOrdersCountChange(response?.ordersCount)); sendNewChatMsgNotification(loginUser, dispatch, chatCount, response?.unreadChatCount);
                    dispatch(onChatCountTChange(response?.unreadChatTracerCount));
                    dispatch(onChatCountCTChange(response?.unreadChatCTCount));
                    dispatch(onChatCountCChange(response?.unreadChatCustomsCount)); dispatch(onChatCountChange(response?.unreadChatCount)); sendNewOrderNotification(loginUser, dispatch, userSpecificOrdersCount, response?.userSpecificOrdersCount); dispatch(onUserSpecificOrdersCountChange(response?.userSpecificOrdersCount)); dispatch(onUnConfirmedOrdersCountChange(response?.unConfirmedOrdersCount));
                    sendRequest(
                        `/api/order?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, str, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, fileNumber, etaDate, etdDate)}&page=${prevPage?.page == page && addSearchFilter(customerId, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, str, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, fileNumber, etaDate, etdDate)?.length ? 0 : page}&size=${size}`,
                        REQUEST_ACTIONS.GET,
                        '',
                        {
                            successCallback: response => {
                                dispatch(setOrderUpdateNum(false));
                                setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
                                setFilterSpinner(false);
                                if (prevPage?.page == page && addSearchFilter(customerId, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, str, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, fileNumber, etaDate, etdDate)) {
                                    dispatch(onPageChange(0));
                                }
                            },
                            failedCallback1,
                        },
                    );
                },
                failedCallback1,
            });
        }
    }, [
        consigneeId,
        shipperId,
        countryOfOriginId, originState,
        shippingModeId,
        subModeId,
        userLocalId,
        userTracer,
        userCustoms,
        userAbroadId,
        dueDate,
        lastUpdateDate,
        ilReminderDate,
        reminderDate,
        selectedLocationIndex,
        searchText, search,
        setFilterSpinner,
        dispatch,
        startReminderDate,
        endReminderDate,
        startIlReminderDate,
        endIlReminderDate,
        stageSearch,
        stageStateSearch,
        reminderDateAdv, ilReminderDateAdv,
        etaDate,
        etdDate,
    ]);
    useEffect(() => {
        if (!location?.state?.search && !orderUpdateNum) {
            setFilterSpinner(true);
            dispatch(setOrderUpdateNum(true));
            sendRequest(
                `/api/order?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, fileNumber, etaDate, etdDate)}&page=${prevPage?.page == page && addSearchFilter(customerId, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, fileNumber, etaDate, etdDate)?.length ? 0 : page}&size=${size}`,
                REQUEST_ACTIONS.GET,
                '',
                {
                    successCallback: response => {
                        dispatch(setOrderUpdateNum(false));
                        setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
                        setFilterSpinner(false);
                        if (prevPage?.page == page && addSearchFilter(customerId, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, fileNumber, etaDate, etdDate)) {
                            dispatch(onPageChange(0));
                        }
                    },
                    failedCallback1,
                },
            );
            // filtered orders get

        } else {
            window.history.replaceState({}, '');
        }
    }, [
        page,
        size,
    ]);

    useEffect(() => {
        var names = userILNames.filter(name => name.department == null || name.department == 1);
        var tracers = userILNames.filter(name => name.department == 2);
        var customs = userILNames.filter(name => name.department == 3);
        setUserILImporterNames(names);
        setUserILTracerNames(tracers);
        setUserILCustomsNames(customs);
    }, [userILNames]);

    useEffect(() => {
        return () => {
            /*clearInterval(dataRefreshInterval.current);
            dataRefreshInterval.current = null;
            clearInterval(chatRefreshInterval.current);
            chatRefreshInterval.current = null;*/
            clearInterval(chatInterval);
            dispatch(setChatInterval(null));
            clearInterval(dataInterval);
            dispatch(setDataInterval(null));
            clearInterval(orderTimeout);
            dispatch(setDataInterval(null));
        }
    }, []);

    useEffect(() => {
        if (loginUser.authority != "Admin") {
            sendRequest(
                `/api/chat/list?${addOrderFilter(loginUser, '', selectedLocationIndex)}`,
                REQUEST_ACTIONS.GET,
                '',
                {
                    successCallback: response => {
                        dispatch(onChatListChange(response))
                    },
                    failedCallback,
                },
            );
            sendRequest(
                `/api/chat/customerChat?${addOrderFilter(loginUser, '', selectedLocationIndex)}`,
                REQUEST_ACTIONS.GET,
                '',
                {
                    successCallback: response => {
                        dispatch(onCustChatListChange(response))
                    },
                    failedCallback,
                },
            );
        }
        if (loginUser.authority !== "Admin") {
            var chatInterval1 = setInterval(() => {
                sendRequest(
                    `/api/chat/list?${addOrderFilter(loginUser, '', selectedLocationIndex)}`,
                    REQUEST_ACTIONS.GET,
                    '',
                    {
                        successCallback: response => {
                            dispatch(onChatListChange(response));
                        },
                        failedCallback,
                    },
                );
                sendRequest(
                    `/api/chat/customerChat?${addOrderFilter(loginUser, '', selectedLocationIndex)}`,
                    REQUEST_ACTIONS.GET,
                    '',
                    {
                        successCallback: response => {
                            dispatch(onCustChatListChange(response))
                        },
                        failedCallback,
                    },
                );
            }, 100000);
            //chatRefreshInterval.current = chatInterval;
            dispatch(setChatInterval(chatInterval1))
        }
        return () => {
        }
    }, []);

    useEffect(() => {
        /*if (dataRefreshInterval.current) {
            clearInterval(dataRefreshInterval.current);
            dataRefreshInterval.current = null;
        }
        if (chatRefreshInterval.current) {
            clearInterval(chatRefreshInterval.current);
            chatRefreshInterval.current = null;
        }*/
        /*if (dataInterval) {
            clearInterval(dataInterval);
            dispatch(setDataInterval(null));
        }*/
        if (orderTimeout) {
            clearInterval(orderTimeout);
            dispatch(setDataInterval(null));
        }
        loginUser123 = loginUser;
        pageName123 = pageName;
        selectedLocationIndex123 = selectedLocationIndex;
        customerId123 = customerId;
        urgentShipment123 = urgentShipment;
        userLocalNumber123 = userLocalNumber;
        userAbroadNumber123 = userAbroadNumber;
        consigneeId123 = consigneeId;
        shipperId123 = shipperId;
        countryOfOriginId123 = countryOfOriginId;
        originState123 = originState;
        shippingModeId123 = shippingModeId;
        subModeId123 = subModeId;
        userLocalId123 = userLocalId;
        userTracer123 = userTracer;
        userCustoms123 = userCustoms;
        userAbroadId123 = userAbroadId;
        dueDate123 = dueDate;
        search123 = search;
        searchText123 = searchText;
        lastUpdateDate123 = lastUpdateDate;
        reminderDate123 = reminderDate;
        ilReminderDate123 = ilReminderDate;
        startReminderDate123 = startReminderDate;
        endReminderDate123 = endReminderDate;
        startIlReminderDate123 = startIlReminderDate;
        endIlReminderDate123 = endIlReminderDate;
        stageSearch123 = stageSearch;
        stageStateSearch123 = stageStateSearch;
        reminderDateAdv123 = reminderDateAdv;
        ilReminderDateAdv123 = ilReminderDateAdv;
        pageName123 = pageName;
        fileNumber123 = fileNumber;
        etaDate123 = etaDate;
        etdDate123 = etdDate;
        orderTimeout = setInterval(() => {
            if (true) {
                dispatch(setOrderUpdateNum(true));
                //console.log("interval data")
                sendRequest(`/api/order/count?${addOrderFilter(loginUser123, pageName123, selectedLocationIndex123)}${addSearchFilter(customerId123, urgentShipment123, userLocalNumber123, userAbroadNumber123, consigneeId123, shipperId123, countryOfOriginId123, originState123, shippingModeId123, subModeId123, userLocalId123, userTracer123, userCustoms123, userAbroadId123, dueDate123, search123 ? search123 : searchText123, lastUpdateDate123, reminderDate123, ilReminderDate123, startReminderDate123, endReminderDate123, startIlReminderDate123, endIlReminderDate123, stageSearch123, stageStateSearch123, reminderDateAdv123, ilReminderDateAdv123, pageName123, fileNumber123, etaDate123, etdDate123)}`, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {
                        dispatch(onOrdersCountChange(response?.ordersCount));
                        sendNewChatMsgNotification(loginUser, dispatch, chatCount, response?.unreadChatCount);
                        dispatch(onChatCountTChange(response?.unreadChatTracerCount));
                        dispatch(onChatCountCTChange(response?.unreadChatCTCount));
                        dispatch(onChatCountCChange(response?.unreadChatCustomsCount)); dispatch(onChatCountChange(response?.unreadChatCount)); sendNewOrderNotification(loginUser, dispatch, userSpecificOrdersCount, response?.userSpecificOrdersCount); dispatch(onUserSpecificOrdersCountChange(response?.userSpecificOrdersCount)); dispatch(onUnConfirmedOrdersCountChange(response?.unConfirmedOrdersCount));
                        sendRequest(
                            `/api/order?${addOrderFilter(loginUser, pageName123, selectedLocationIndex123)}${addSearchFilter(customerId123, urgentShipment123, userLocalNumber123, userAbroadNumber123, consigneeId123, shipperId123, countryOfOriginId123, originState123, shippingModeId123, subModeId123, userLocalId123, userTracer123, userCustoms123, userAbroadId123, dueDate123, search123 ? search123 : searchText123, lastUpdateDate123, reminderDate123, ilReminderDate123, startReminderDate123, endReminderDate123, startIlReminderDate123, endIlReminderDate123, stageSearch123, stageStateSearch123, reminderDateAdv123, ilReminderDateAdv123, pageName123, fileNumber123, etaDate123, etdDate123)}&page=${prevPage?.page == page && addSearchFilter(customerId123, userLocalNumber123, userAbroadNumber123, consigneeId123, shipperId123, countryOfOriginId123, originState123, shippingModeId123, subModeId123, userLocalId123, userTracer123, userCustoms123, userAbroadId123, dueDate123, search123 ? search123 : searchText123, lastUpdateDate123, reminderDate123, ilReminderDate123, startReminderDate123, endReminderDate123, startIlReminderDate123, endIlReminderDate123, stageSearch123, stageStateSearch123, reminderDateAdv123, ilReminderDateAdv123, pageName123, fileNumber123, etaDate123, etdDate123)?.length ? 0 : page}&size=${size}`,
                            REQUEST_ACTIONS.GET,
                            '',
                            {
                                successCallback: response => {
                                    dispatch(setOrderUpdateNum(false));
                                    setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
                                    if (prevPage?.page == page && addSearchFilter(customerId123, userLocalNumber123, userAbroadNumber123, consigneeId123, shipperId123, countryOfOriginId123, originState123, shippingModeId123, subModeId123, userLocalId123, userTracer123, userCustoms123, userAbroadId123, dueDate123, search123 ? search123 : searchText123, lastUpdateDate123, reminderDate123, ilReminderDate123, startReminderDate123, endReminderDate123, startIlReminderDate123, endIlReminderDate123, stageSearch123, stageStateSearch123, reminderDateAdv123, ilReminderDateAdv123, pageName123, fileNumber123, etaDate123, etdDate123)) {
                                        dispatch(onPageChange(0));
                                    }
                                },
                                failedCallback1,
                            },
                        );
                    },
                    failedCallback1,
                });
                // filtered orders get

            }
        }, 40000);
        //dataRefreshInterval.current = interval;
        //dispatch(setDataInterval(interval));

        /*if (loginUser.authority != "Admin") {
            sendRequest(
                `/api/chat/list?${addOrderFilter(loginUser, '', selectedLocationIndex)}`,
                REQUEST_ACTIONS.GET,
                '',
                {
                    successCallback: response => {
                        dispatch(onChatListChange(response))
                    },
                    failedCallback,
                },
            );
            sendRequest(
                `/api/chat/customerChat?${addOrderFilter(loginUser, '', selectedLocationIndex)}`,
                REQUEST_ACTIONS.GET,
                '',
                {
                    successCallback: response => {
                        dispatch(onCustChatListChange(response))
                    },
                    failedCallback,
                },
            );
        }*/
        
    }, [
        consigneeId,
        shipperId,
        countryOfOriginId, originState,
        shippingModeId,
        subModeId,
        userLocalId,
        userTracer,
        userCustoms,
        userAbroadId,
        dueDate,
        lastUpdateDate,
        ilReminderDate,
        reminderDate,
        selectedLocationIndex,
        searchText,
        page,
        size,
        setFilterSpinner,
        dispatch,
        startReminderDate,
        endReminderDate,
        startIlReminderDate,
        endIlReminderDate,
        stageSearch,
        stageStateSearch,
        reminderDateAdv, ilReminderDateAdv,
        etaDate,
        etdDate,
    ]);

    return (
        <TableHead>
            <TableRow className="header">
                {(selectedLocationIndex == 2 || selectedLocationIndex == 3) && <StyledTableCell colSpan="1" sx={{ padding: "3px", pl: 0, pr: 0, width: "7vw", minWidth: "7vw", maxWidth: "7vw" }}>
                    <OrderSearch loginUser={loginUser} setSearchSpinner={setFilterSpinner} pageName={pageName} searchText={searchText} setSearchText={setSearchText1} />
                </StyledTableCell>}
                {(selectedLocationIndex == 0 || selectedLocationIndex == 1) && <><StyledTableCell colSpan="2" sx={{ padding: "3px", pl: 0, pr: 0, width: "1vw", minWidth: "1vw", maxWidth: "2vw" }}>
                    <OrderSearch loginUser={loginUser} setSearchSpinner={setFilterSpinner} pageName={pageName} searchText={searchText} setSearchText={setSearchText1} />
                </StyledTableCell>
                    {/*<StyledTableCell colSpan="1" sx={{ padding: "3px", pl: 0, pr: 0, width: "3vw", minWidth: "3vw", maxWidth: "3vw" }}>
                        NL#
                    </StyledTableCell>*/}</>}
                {((selectedLocationIndex == 0 || selectedLocationIndex == 1) && loginUser.authority != "Admin" && loginUser.department == null || loginUser.department == 1) && <StyledTableCell sx={{ maxWidth: "6vw", minWidth: "6vw", width: "6vw", p: 0, lineHeight: '1' }}>
                    {(loginUser.location.code === "IL") && <>
                        <SelectField
                            sm={null}
                            md={null}
                            lg={null}
                            label={<Typography fontSize="0.8vw" fontWeight='bold'>F/U</Typography>}
                            value={userLocalId}
                            onChange={event => onHandleChange(event, onUserLocalIdChange)}
                            options={[{ id: null, name: 'Reset' }, ...userILImporterNames]}
                        />
                    </>}
                    {(loginUser.location.code != "IL") && <>
                        {(loginUser.location.code === 'US') ? <SelectField
                            sm={null}
                            md={null}
                            lg={null}
                            label={<Typography fontSize="0.8vw" fontWeight='bold'>US</Typography>}
                            value={userAbroadId}
                            onChange={event => onHandleChange(event, onUserAbroadIdChange)}
                            options={[{ id: null, name: 'Reset' }, ...userUSNames]}
                        /> : <SelectField
                            sm={null}
                            md={null}
                            lg={null}
                            label={<Typography fontSize="0.8vw" fontWeight='bold'>NL</Typography>}
                            value={userAbroadId}
                            onChange={event => onHandleChange(event, onUserAbroadIdChange)}
                            options={[{ id: null, name: 'Reset' }, ...userNLNames]}
                        />}
                    </>}
                </StyledTableCell>}
                {(loginUser.department == 2 || loginUser.department == 3) && loginUser.authority != "Admin" && <StyledTableCell sx={{ minWidth: "6vw", maxWidth: "6vw", width: "6vw", p: 0, lineHeight: '1' }}>
                    {((loginUser.department == 2)) && <SelectField
                        sm={null}
                        md={null}
                        lg={null}
                        label={<Typography fontSize="0.8vw" fontWeight='bold'>OPS</Typography>}
                        value={userTracer}
                        onChange={event => onHandleChange(event, onUserTracerChange)}
                        options={[{ id: null, name: 'Reset' }, ...userILTracerNames]}
                    />}
                    {((loginUser.department == 3)) && <SelectField
                        sm={null}
                        md={null}
                        lg={null}
                        label={<Typography fontSize="0.8vw" fontWeight='bold'>Customs</Typography>}
                        value={userCustoms}
                        onChange={event => onHandleChange(event, onUserCustomsChange)}
                        options={[{ id: null, name: 'Reset' }, ...userILCustomsNames]}
                    />}
                </StyledTableCell>}
                <StyledTableCell sx={{ padding: "11px", pl: 0, pr: 0, width: "5vw", minWidth: "5vw", maxWidth: "5vw" }}>
                    <SelectField
                        sm={null}
                        md={null}
                        lg={null}
                        label={<Typography fontSize="0.8vw" fontWeight='bold'>Type</Typography>}
                        value={shippingModeId}
                        onChange={event => onHandleChange(event, onShippingModeIdChange)}
                        options={[{ id: null, name: 'Reset' }, ...shippingModeData]}
                    />
                </StyledTableCell>
                {/*<StyledTableCell sx={{ padding: "11px", pl: 0, pr: 0, width: "7vw" }}>
          <SelectField
            sm={null}
            md={null}
            lg={null}
            label={<Typography fontSize="0.8vw" fontWeight='bold'>Mode</Typography>}
            value={subModeId}
            onChange={event => onHandleChange(event, onSubModeIdChange)}
            options={[{ id: null, name: 'Reset' }, ...subModeData]}
          />
        </StyledTableCell>*/}
                <StyledTableCell sx={{ padding: "11px", pl: 0, pr: 0, width: `${selectedLocationIndex == 1?"7vw":"4vw"}`, minWidth: `${selectedLocationIndex == 1?"7vw":"4vw"}`, maxWidth: `${selectedLocationIndex == 1?"7vw":"4vw"}`}}>
                    {(loginUser.location.code === 'US' || selectedLocationIndex == 1) ?
                        (<SelectField
                            sm={null}
                            md={null}
                            lg={null}
                            label={<Typography fontSize="0.8vw" fontWeight='bold'>Org</Typography>}
                            value={originState}
                            onChange={event => onHandleChange(event, onOriginStateChange)}
                            options={[{ id: null, name: 'Reset' }, ...USA_STATES]}
                        />) :
                        (<SelectField
                            sm={null}
                            md={null}
                            lg={null}
                            label={<Typography fontSize="0.8vw" fontWeight='bold'>{selectedLocationIndex == 2 ? 'Org' : 'Org'}</Typography>}
                            value={countryOfOriginId}
                            onChange={event => onHandleChange(event, onCountryOfOriginIdChange)}
                            options={[{ id: null, name: 'Reset' }, ...countryOfOriginData]}
                        />)}
                </StyledTableCell>

                {selectedLocationIndex == 2 && <StyledTableCell sx={{ maxWidth: "5vw", minWidth: "5vw", width: "5vw", p: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', lineHeight: '1' }}>

                    <p style={{ textAlign: "left", fontWeight: "500", width: "100%" }}>Broker</p>
                </StyledTableCell>}
                <StyledTableCell sx={{ padding: "11px", pl: 0, pr: 0, width: '7vw', maxWidth: '7vw', minWidth: '7vw' }}>
                    <SelectField
                        sm={null}
                        md={null}
                        lg={null}
                        label={<Typography fontSize="0.8vw" fontWeight='bold'>Shipper</Typography>}
                        value={shipperId}
                        onChange={event => onHandleChange(event, onShipperIdChange)}
                        options={[{ id: null, name: 'Reset' }, ...shipperNameOptions]}
                    />
                </StyledTableCell>
                <StyledTableCell sx={{ padding: "11px", pl: 0, pr: 0, width: '7vw', maxWidth: '7vw', minWidth: '7vw' }}>
                    <SelectField
                        sm={null}
                        md={null}
                        lg={null}
                        label={<Typography fontSize="0.8vw" fontWeight='bold'>Consignee</Typography>}
                        value={consigneeId}
                        onChange={event => onHandleChange(event, onConsigneeIdChange)}
                        options={[{ id: null, name: 'Reset' }, ...consigneeNameOptions]}
                    />
                </StyledTableCell>
                {/*<StyledTableCell sx={{ padding: "11px", pl: 0, pr: 0, width: "7vw" }} >
          <SelectField
            sm={null}
            md={null}
            lg={null}
            label={<Typography fontSize="0.8vw" fontWeight='bold'>Stage</Typography>}
            options={STAGES_OPTIONS}
            value={stageSearch}
            onChange={event => onHandleChange(event, OnStageSearchChange)}
          />
              </StyledTableCell>
              <StyledTableCell sx={{ padding: "11px", pl: 0, pr: 0, width: "9vw" }} >
                  <SelectField
                      sm={null}
                      md={null}
                      lg={null}
                      label={<Typography fontSize="0.8vw" fontWeight='bold'>Urgent Shipment</Typography>}
                      options={[{ id: null, name: 'Reset' }, { id: 1, name: 'Urgent' }, { id: 2, name: 'Not Urgent' }]}
                      value={urgentShipment}
                      onChange={event => onHandleChange(event, onUrgentShipmentChange)}
                  />
              </StyledTableCell>*/}
                <StyledTableCell sx={{ padding: "11px", pl: 0, pr: 0, width: "6vw", minWidth: "6vw", maxWidth: "6vw" }} align='center' className="headerDate">
                    <DateTimePicker
                        sm={3}
                        md={3}
                        lg={3}
                        label='Reminder'
                        boldLabel={<Typography fontSize="0.8vw" fontWeight='bold'>Reminder</Typography>}
                        value={ilReminderDate}
                        isShowTime={false}
                        format="dd/MM/yyyy"
                        onChange={date => dispatch(onIlReminderDateChange(date?.toISOString()))}
                    />
                </StyledTableCell>
                <StyledTableCell sx={{ padding: "11px", pl: 0, pr: 0, width: "6vw", minWidth: "6vw", maxWidth: "6vw" }} align='center' className="headerDate">
                    <DateTimePicker
                        sm={3}
                        md={3}
                        lg={3}
                        label='ETD'
                        boldLabel={<Typography fontSize="0.8vw" fontWeight='bold'>ETD</Typography>}
                        value={etdDate}
                        isShowTime={false}
                        format="dd/MM/yyyy"
                        onChange={date => dispatch(onETDChange(date?.toISOString()))}
                    />
                </StyledTableCell>
                <StyledTableCell sx={{ padding: "11px", pl: 0, pr: 0, width: "6vw", minWidth: "6vw", maxWidth: "6vw" }} align='center' className="headerDate">
                    <DateTimePicker
                        sm={3}
                        md={3}
                        lg={3}
                        label='ETA'
                        boldLabel={<Typography fontSize="0.8vw" fontWeight='bold'>ETA</Typography>}
                        value={etaDate}
                        isShowTime={false}
                        format="dd/MM/yyyy"
                        onChange={date => dispatch(onETAChange(date?.toISOString()))}
                    />
                </StyledTableCell>
                <StyledTableCell sx={{ padding: "11px", pl: 0, pr: 0, width: '6vw' }}>
                    {/*<p style={{ textAlign: "left", fontWeight:"500", width: "100%" }}>POD</p>*/}
                </StyledTableCell>
                <StyledTableCell sx={{ padding: "11px", pl: 0, pr: 0, width: "800vw" }} align='center' colSpan="20">
                    <Grid item container flexWrap="nowrap">
                        {/*<Tooltip title='Reminder Search'>
                            <ManageSearchIcon onClick={onAdvSearchDialogHandleOpen} style={(startReminderDate != null || endReminderDate != null || startIlReminderDate != null || endIlReminderDate != null || reminderDateAdv != null || ilReminderDateAdv != null) ? advancedSearchSelectedStyle : advancedSearchDefaultStyle} />
                        </Tooltip>*/}
                        <Tooltip title='Reset Search'>
                            <SearchOffIcon style={advancedSearchDefaultStyle} fontSize='medium' onClick={() => dispatch(resetOrderTableFilterSlice(null))} />
                        </Tooltip>
                    </Grid>
                </StyledTableCell>
                {/*<StyledTableCell sx={{ padding: "11px", pl: 0, pr: 0, width: "8vw" }} align='center' className="headerDate" colSpan="4">
                    {loginUser?.location?.code === 'IL' ?

                        <Grid lg={12} container item wrap='nowrap' justifyContent='center' flexDirection='row' alignItems='center' className="padding_0">
                            <Grid container item lg={9} align='left'>
                                <DateTimePicker
                                    sm={12}
                                    md={12}
                                    label='Reminder'
                                    boldLabel={<Typography fontSize="0.8vw" fontWeight='bold'>Reminder</Typography>}
                                    value={ilReminderDate}
                                    isShowTime={false}
                                    format="dd/MM/yyyy"
                                    onChange={date => dispatch(onIlReminderDateChange(date?.toISOString()))}
                                /></Grid>
                            <Grid container item lg={3}>
                                {<Typography fontSize="0.8vw" fontWeight='bold'><Tooltip title='Reminder Search'><ManageSearchIcon onClick={onAdvSearchDialogHandleOpen} style={(startReminderDate != null || endReminderDate != null || startIlReminderDate != null || endIlReminderDate != null || reminderDateAdv != null || ilReminderDateAdv != null) ? advancedSearchSelectedStyle : advancedSearchDefaultStyle} /></Tooltip><Tooltip title='Reset Search'><SearchOffIcon style={advancedSearchDefaultStyle} fontSize='medium' onClick={() => dispatch(resetOrderTableFilterSlice(null))} /></Tooltip>
                                    {openAdvSearchDialog && (
                                        <AdvancedSearchDialog openDialog={openAdvSearchDialog} setOpenDialog={setOpenAdvSearchDialog} loginUser={loginUser} pageName={pageName} />
                                    )} </Typography>}</Grid></Grid> :
                        <Grid lg={12} container item wrap='nowrap' justifyContent='center' flexDirection='row' alignItems='center' className="padding_0">
                            <Grid container item lg={9}>
                                <DateTimePicker
                                    sm={null}
                                    md={null}
                                    label='Reminder'
                                    boldLabel={<Typography fontSize="0.8vw" fontWeight='bold'>Reminder</Typography>}
                                    value={reminderDate}
                                    isShowTime={false}
                                    format="dd/MM/yyyy"
                                    onChange={date => dispatch(onReminderDateChange(date?.toISOString()))}
                                /></Grid>
                            <Grid container item lg={4}>

                                {<Typography fontSize="0.8vw" fontWeight='bold' style={{ display: 'flex' }}>
                                    <Tooltip title='Reminder Search'>
                                        <ManageSearchIcon onClick={onAdvSearchDialogHandleOpen} style={(startReminderDate != null || endReminderDate != null || startIlReminderDate != null || endIlReminderDate != null || reminderDateAdv != null || ilReminderDateAdv != null) ? advancedSearchSelectedStyle : advancedSearchDefaultStyle} />
                                    </Tooltip>
                                    <Tooltip title='Reset Search'>
                                        <SearchOffIcon style={advancedSearchDefaultStyle} fontSize='medium' onClick={() => dispatch(resetOrderTableFilterSlice(null))} />
                                    </Tooltip>
                                    {openAdvSearchDialog && (
                                        <AdvancedSearchDialog openDialog={openAdvSearchDialog} setOpenDialog={setOpenAdvSearchDialog} loginUser={loginUser} pageName={pageName} />
                                    )} </Typography>}</Grid></Grid>
                    }

                </StyledTableCell>*/}


                {/* <StyledTableCell sx={{ padding: "11px" }} align='center' colSpan="3">

                  {<Typography fontSize="0.8vw" fontWeight='bold'>OPERATIONS</Typography>}

              </StyledTableCell>*/}

            </TableRow>
        </TableHead>
    );
};

export default OrderTableHeader;
