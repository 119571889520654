import { createSlice } from '@reduxjs/toolkit';


const arraysEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export const ordersSlice = createSlice({
  name: 'orders',
  initialState: {
    orders: null,
    page: 0,
    size: 20,
    ordersCount: 0,
    chatCount: 0,
    chatCountT: 0,
    chatCountC: 0,
    chatCountCT: 0,
    userSpecificOrdersCount: 0,
    unConfirmedOrdersCount: 0,
      orderSelected: 0,
      chatListNotification:[],
      custChatListNotification:[],
  },
  reducers: {
    getOrders: (state, { payload }) => {
      if(!arraysEqual(state.orders, payload)){
        state.orders = payload;
      }
    },
    onPageChange: (state, { payload }) => {
      state.page = payload;
      },
      onSizeChange: (state, { payload }) => {
          state.size = payload;
      },
      onChatListChange: (state, { payload }) => {
          state.chatListNotification = payload;
      },
      onCustChatListChange: (state, { payload }) => {
          state.custChatListNotification = payload;
      },
    onOrdersCountChange: (state, { payload }) => {
      state.ordersCount = payload;
      },
      onChatCountChange: (state, { payload }) => {
          state.chatCount = payload;
      },
      onChatCountTChange: (state, { payload }) => {
          state.chatCountT = payload;
      },
      onChatCountCChange: (state, { payload }) => {
          state.chatCountC = payload;
      },
      onChatCountCTChange: (state, { payload }) => {
          state.chatCountCT = payload;
      },
    onUserSpecificOrdersCountChange: (state, { payload }) => {
      state.userSpecificOrdersCount = payload;
    },
    onUnConfirmedOrdersCountChange: (state, { payload }) => {
      state.unConfirmedOrdersCount = payload;
    },
    onOrderSelected: (state, { payload }) => {
      state.orderSelected = payload;
    },
    addOrder: (state, { payload }) => {
      state.orders = [...state.orders, payload];
    },
    updateOrder: (state, { payload }) => {
      const { id, updatedOrder } = payload;
      const order = state.orders.find(order => order.id === id);
      const orderIndex = state.orders.indexOf(order);
      const before = state.orders.slice(0, orderIndex);
      const after = state.orders.slice(orderIndex + 1);
      const newOrders = [...before, updatedOrder, ...after];
      state.orders = newOrders;
    },
    deleteOrder: (state, { payload }) => {
      state.orders = state.orders.filter(order => order.id !== payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { onCustChatListChange, onChatListChange, getOrders, addOrder, updateOrder, deleteOrder, onPageChange, onSizeChange, onOrdersCountChange, onChatCountTChange, onChatCountCChange, onChatCountCTChange, onChatCountChange, onUserSpecificOrdersCountChange, onOrderSelected, onUnConfirmedOrdersCountChange } =
  ordersSlice.actions;

export default ordersSlice.reducer;
