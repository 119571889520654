import { useEffect, useCallback, useState, useRef } from 'react';

import { Box, Typography, Grid, Button, Divider, CircularProgress, FormControlLabel, Checkbox } from '@mui/material';
import { SelectField, TextField, DateTimePicker, MultilineField, Dropzone, FilesRender, Dialog } from 'style-guide';

import { STATUS } from 'constants/global-constants';
import { REQUEST_ACTIONS, sendRequest, setOrderData, updateOrder } from 'utils/Communicator';

import { useDispatch, useSelector } from 'react-redux';
import { getOrders } from 'reducers/order-reducers/ordersSlicer';
import AddServiceProvider from 'serviceProvider/AddServiceProvider';
import {
    resetShipperStage,
    onIdChange,
    onFilesChange,
    onFilesAdd,
    onStageStatusInfoIdChange,
    onShipmentAddressChange,
    onNextActionDateChange,
    onRequiredTimeSlotChange,
    onShipperDateChange,
    onPickupDateChange,
    onNotesChange,
    onShipperStatusesChange,
} from 'reducers/stage-reducers/shipperStageSlicer';
import { snackbarToggle } from 'reducers/snackbarSlicer';
import {
    onUserUSDataChange,
    onUserNLDataChange,
    onUserILDataChange,
} from 'reducers/order-reducers/predefinedDataSlicer';
import { addOrderFilter, addSearchFilter, addStatusChatMessage, getStatusChatMessage } from 'Utils';

const ShipperDialog = ({ loginUser, order, openDialog, setOpenDialog, pageName }) => {
    const dispatch = useDispatch();
    const onHandleChange = (event, action) => dispatch(action(event.target.value));
    const [openShipperModal, setOpenShipperModal] = useState(false);
    const onHandleOpenShipperModal = () => setOpenShipperModal(true);
    const onHandleCancelShipperModal = () => setOpenShipperModal(false);
    const { userILData, userNLData, userUSData } = useSelector(state => state.predefinedData);

    const { orders, page, size } = useSelector(state => state.orders);
    const {
        id,
        stageStatusInfoId,
        shipperStatuses,
        shipmentAddress,
        nextActionDate,
        requiredTimeSlot,
        estimatedPickupDate,
        pickupDate,
        notes,
        files,
    } = useSelector(state => state.shipperStage);
    const { countryOfOriginData } = useSelector(state => state.predefinedData);

    const [isLoaded, setIsLoaded] = useState(false);

    const [ilUsers, setIlUsers] = useState();
    const [nlUsers, setNlUsers] = useState();
    const [usUsers, setUSUsers] = useState();
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);

    const currentStage = order.orderStage[1];

    const { incotermsData, airPolData, airPoaData, lclPolData, lclPoaData, subModeData, fclType20Data, fclType40Data } =
        useSelector(state => state.predefinedData);
    const [fiileList, setFiileList] = useState([]);
    const setFilesAdd = (data) => {
        var fi = Object.assign([], (fiileList?.length ? fiileList : []));
        fi.push(data);
        setFiileList(fi);
        onFilesAdd(data);
    }
    useEffect(() => {
        setFiileList(files);
    }, [files]);
    const getComments = (str) => {
        var strs1 = [];
        var strs = [];
        strs = str.split("\n");
        for (var i = 0; i < strs.length; i++) {
            //if (isHebrew(strs[i]))
            //    strs1.push(encodeURI(strs[i]));
            //else
            strs1.push(strs[i]);
        }
        return strs1.join("<br/>");
    }


    const getKindOfPieces = () => {
        if (order.shippingModeId == 3) {
            if (order.orderFCLDetails) {
                var fcl20 = "";
                var fcl40 = "";
                if (order.orderFCLDetails?.fclType20Id) {
                    fcl20 = fclType20Data.find(dat => dat.id == order.orderFCLDetails?.fclType20Id)?.name
                }
                if (order.orderFCLDetails?.fclType40Id) {
                    fcl40 = fclType40Data.find(dat => dat.id == order.orderFCLDetails?.fclType40Id)?.name
                }
                if (fcl20 && fcl40)
                    return fcl20 + ", " + fcl40;
                if (fcl20 && !fcl40)
                    return fcl20;
                if (!fcl20 && fcl40)
                    return fcl40;
                return "";
            }
        }
        return "";
    }
    const getTotalPieces = () => {
        var num = 0;
        for (var i = 0; i < order.orderDetails?.length; i++) {
            num += order.orderDetails[i].numberOfPieces;
        }
        if (order.orderFCLDetails?.quantity20) {
            num += order.orderFCLDetails.quantity20;
        }
        if (order.orderFCLDetails?.quantity40) {
            num += order.orderFCLDetails.quantity40;
        }
        return num ? num : "";
    }
    const getTo = (poaId, shippingModeId, bol) => {
        try {
            var str = "";
            if (shippingModeId == 1) {
                str = airPoaData.find(poa => poa.id == poaId)?.name
            }
            else str = lclPoaData.find(poa => poa.id == poaId)?.name
            if (str)
                return str;
            else if (bol)
                str = countryOfOriginData.find((ctr) => ctr.id == order.countryOfOriginId)?.name;
            return str ? str : "";
        } catch (e) {
            return "N/A";
        }
    }
    const getFrom = (polId, shippingModeId) => {
        try {
            if (shippingModeId == 1) {
                return airPolData.find(pol => pol.id == polId).name
            }
            return lclPolData.find(pol => pol.id == polId).name
        } catch (e) {
            return "N/A"
        }
    }
    const getType = (shippingModeId) => {
        try {
            if (shippingModeId == 1) {
                return "Air";
            } else if (shippingModeId == 2) {
                return "LCL";
            } else {
                return "FCL";
            }
        } catch (e) {
            return "N/A"
        }
    }
    const getTotalWeight = () => {
        var wt = 0;
        for (var i = 0; i < order.orderDetails.length; i++) {
            wt += order.orderDetails[i].weightPerPiece != 0 ? (order.orderDetails[i].numberOfPieces * order.orderDetails[i].weightPerPiece) : order.orderDetails[i].totalweight;
        }
        return wt ? wt.toFixed(2) : "";
    }
    const getChWeight = () => {
        var vt = 0;
        var manualwt = 0;
        for (var i = 0; i < order.orderDetails.length; i++) {
            var vol = order.orderDetails[i].totalvolume ? order.orderDetails[i].totalvolume : (order.orderDetails[i]['length'] * order.orderDetails[i].width * order.orderDetails[i].height / 1000000);
            vt = vt + (order.orderDetails[i].totalvolume ? 1 : order.orderDetails[i].numberOfPieces) * (vol);
        }
        return vt ? vt.toFixed(2) : "";
    }
    const formatDate = (date) => {
        if (!date)
            return "-";
        var str = [];
        if (date.includes("T")) {
            str = date.split("T")[1].split(":");
            var time = str[0] + ":" + str[1];

            const dt = new Date(date);
            const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
            return `${padL(dt.getDate())}/${padL(dt.getMonth() + 1)}/${dt.getFullYear().toString().substr(-2)} ${time}`;
        } else {
            return date;
        }
    }
    const formatDateTime = (date) => {
        if (!date)
            return "";
        var dt = new Date(Number(date));
        const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
        return `${padL(dt.getDate())}/${padL(dt.getMonth() + 1)}/${dt.getFullYear().toString().substr(-2)} ${padL(dt.getHours())}:${padL(dt.getMinutes())}`;
    }
    const [showMail, setShowMail1] = useState(false);
    const getCompanyShortName = (company) => {
        if (company?.companyShortName)
            return company.companyShortName?.substring(0, 11).toUpperCase();
        else
            return company.companyName?.substring(0, 11).toUpperCase();
    }

    const setShowMail = () => {
        showMail ? setShowMail1(false) : setShowMail1(true);
        var sub = getType(order.shippingModeId) + " " + getFrom(order.polId, order.shippingModeId, true) + "-" + getTo(order.poaId, order.shippingModeId);
        sub += " | " + getCompanyShortName(order.shipper) + " | " + getCompanyShortName(order.consignee) + ", ref " + order.jobNumber;
        setMailSubject(sub);
    }
    const getTodayDate = () => {
        return new Intl.DateTimeFormat('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }).format(new Date())
    }
    const [mailSubject, setMailSubject] = useState("");
    const [mailBody, setMailBody] = useState("");
    const [mailToAdd, setMailToAdd1] = useState("");

    const [useMailToAgent, setUseMailToAgent] = useState(false);
    const [mailToAgent, setMailToAgent1] = useState("");
    const [mailToAgentValidation, setMailToAgentValidation] = useState(true);
    const setMailToAgent = (str) => {
        setMailToAgent1(str);
        setMailToAgentValidation(validateEmail(str));
    }
    const [useMailToAgent2, setUseMailToAgent2] = useState(false);
    const [mailToAgent2, setMailToAgent12] = useState("");
    const [mailToAgentValidation2, setMailToAgentValidation2] = useState(true);
    const setMailToAgent2 = (str) => {
        setMailToAgent12(str);
        setMailToAgentValidation2(validateEmail(str));
    }
    const [useMailToAgent3, setUseMailToAgent3] = useState(false);
    const [mailToAgent3, setMailToAgent13] = useState("");
    const [mailToAgentValidation3, setMailToAgentValidation3] = useState(true);
    const setMailToAgent3 = (str) => {
        setMailToAgent13(str);
        setMailToAgentValidation3(validateEmail(str));
    }
    const [useMailToAgent4, setUseMailToAgent4] = useState(false);
    const [mailToAgent4, setMailToAgent14] = useState("");
    const [mailToAgentValidation4, setMailToAgentValidation4] = useState(true);
    const setMailToAgent4 = (str) => {
        setMailToAgent14(str);
        setMailToAgentValidation4(validateEmail(str));
    }
    const [useMailToAgent5, setUseMailToAgent5] = useState(false);
    const [mailToAgent5, setMailToAgent15] = useState("");
    const [mailToAgentValidation5, setMailToAgentValidation5] = useState(true);
    const setMailToAgent5 = (str) => {
        setMailToAgent15(str);
        setMailToAgentValidation5(validateEmail(str));
    }
    const [useMailToAdd, setUseMailToAdd] = useState(false);
    const setMailToAdd = (str) => {
        setMailToAdd1(str);
        if (str) {
            var strs = str.split(",");
            var valid = true;
            for (var i = 0; i < strs.length; i++) {
                if (!validateEmail(strs[i]))
                    valid = false;
            }
            setUseMailToAdd(valid);
        }
    }
    const [useMailToCustomer, setUseMailToCustomer] = useState(false);
    const [mailToCustomer, setMailToCustomer] = useState(order?.consignee?.email ? order.consignee.email : "");
    const [mailToCustomerValidation, setMailToCustomerValidation] = useState(order?.consignee?.email ? true : false);

    const [useMailToCustomer2, setUseMailToCustomer2] = useState(false);
    const [mailToCustomer2, setMailToCustomer2] = useState(order?.consignee?.email2 ? order.consignee.email2 : "");
    const [mailToCustomerValidation2, setMailToCustomerValidation2] = useState(order?.consignee?.email2 ? true : false);

    const [useMailToCustomer3, setUseMailToCustomer3] = useState(false);
    const [mailToCustomer3, setMailToCustomer3] = useState(order?.consignee?.email3 ? order.consignee.email3 : "");
    const [mailToCustomerValidation3, setMailToCustomerValidation3] = useState(order?.consignee?.email3 ? true : false);

    const [useMailToCustomer4, setUseMailToCustomer4] = useState(false);
    const [mailToCustomer4, setMailToCustomer4] = useState(order?.consignee?.email4 ? order.consignee.email4 : "");
    const [mailToCustomerValidation4, setMailToCustomerValidation4] = useState(order?.consignee?.email4 ? true : false);

    const [useMailToCustomer5, setUseMailToCustomer5] = useState(false);
    const [mailToCustomer5, setMailToCustomer5] = useState(order?.consignee?.email5 ? order.consignee.email5 : "");
    const [mailToCustomerValidation5, setMailToCustomerValidation5] = useState(order?.consignee?.email5 ? true : false);

    const getCargoType = (type) => {
        if (type === "Cargo")
            return "General Cargo";
        return type;
    }
    const shipperCountry = (order?.shipper?.countryId ? countryOfOriginData.find(ctr => ctr.id == order.shipper.countryId)?.name : "");
    const consigneeCountry = (order?.consignee?.countryId ? countryOfOriginData.find(ctr => ctr.id == order.consignee.countryId)?.name : "");
    const getShipperPhone = (shipper) => {
        if (shipper?.mobile?.trim()) {
            return shipper?.mobile;
        } else if (shipper?.businessPhone?.trim()) {
            return shipper?.businessPhone;
        }
        if (shipper?.mobile2?.trim()) {
            return shipper?.mobile2;
        } else if (shipper?.businessPhone2?.trim()) {
            return shipper?.businessPhone2;
        }
        if (shipper?.mobile3?.trim()) {
            return shipper?.mobile3;
        } else if (shipper?.businessPhone3?.trim()) {
            return shipper?.businessPhone3;
        }
        if (shipper?.mobile4?.trim()) {
            return shipper?.mobile4;
        } else if (shipper?.businessPhone4?.trim()) {
            return shipper?.businessPhone4;
        }
        if (shipper?.mobile5?.trim()) {
            return shipper?.mobile5;
        } else if (shipper?.businessPhone5?.trim()) {
            return shipper?.businessPhone5;
        }
        return "";
    }
    const sendMail = () => {
        if (useMailToAgent && mailToAgent === "") {
            alert("Please enter agent email.")
            return;
        }
        if (useMailToAgent && !validateEmail(mailToAgent)) {
            alert("Please enter a proper agent email.")
            return;
        }
        if (useMailToCustomer && mailToCustomer === "") {
            alert("Please make sure to have proper consignee email.")
            return;
        }
        if (useMailToCustomer && !validateEmail(mailToCustomer)) {
            alert("Please enter a proper consignee email in CRM.")
            return;
        }
        if (!((useMailToAgent && validateEmail(mailToAgent)) ||
            (useMailToAgent2 && validateEmail(mailToAgent2)) ||
            (useMailToAgent3 && validateEmail(mailToAgent3)) ||
            (useMailToAgent4 && validateEmail(mailToAgent4)) ||
            (useMailToAgent5 && validateEmail(mailToAgent5)) ||
            (useMailToCustomer && validateEmail(mailToCustomer)) ||
            (useMailToCustomer2 && validateEmail(mailToCustomer2)) ||
            (useMailToCustomer3 && validateEmail(mailToCustomer3)) ||
            (useMailToCustomer4 && validateEmail(mailToCustomer4)) ||
            (useMailToCustomer5 && validateEmail(mailToCustomer5)) || useMailToAdd)) {
            alert("Select atleast one of agent and/or customer and/or additional e-mails should be valid.");
            return;
        }
        var mtoCust = mailToCustomer;
        if (!useMailToCustomer) {
            mtoCust = null;
        }
        var mtoCust2 = mailToCustomer2;
        if (!useMailToCustomer2) {
            mtoCust2 = null;
        }
        var mtoCust3 = mailToCustomer3;
        if (!useMailToCustomer3) {
            mtoCust3 = null;
        }
        var mtoCust4 = mailToCustomer4;
        if (!useMailToCustomer4) {
            mtoCust4 = null;
        }
        var mtoCust5 = mailToCustomer5;
        if (!useMailToCustomer5) {
            mtoCust5 = null;
        }
        var mtoagent = mailToAgent;
        if (!useMailToAgent) {
            mtoagent = null;
        }
        var mtoagent2 = mailToAgent2;
        if (!useMailToAgent2) {
            mtoagent2 = null;
        }
        var mtoagent3 = mailToAgent3;
        if (!useMailToAgent3) {
            mtoagent3 = null;
        }
        var mtoagent4 = mailToAgent4;
        if (!useMailToAgent4) {
            mtoagent4 = null;
        }
        var mtoagent5 = mailToAgent5;
        if (!useMailToAgent5) {
            mtoagent5 = null;
        }
        var addto = mailToAdd;
        if (!useMailToAdd) {
            addto = null;
        }
        var impt = order.locationId === "2" ? "Export" : "Import";
        /*var mbody = '<br /> <div style={{ textAlign: "center", width: "100%" }}><b>' + impt + ' JOB ORDER No. - ' + order.jobNumber + '</b></div><br /><br /><b> From: </b> <br />' + getFrom(order.polId, order.shippingModeId, false) + ' <br /> <br /><b>To: </b> <br />' + getTo(order.poaId, order.shippingModeId) + ' <br /><br /><b>Shipment Details: </b><table style="text-align: center"><tr><th style="text-decoration: underline">Pieces</th><th style="text-decoration: underline; padding: 5px">L(CM)</th><th style="text-decoration: underline; padding: 5px">W(CM)</th><th style="text-decoration: underline; padding: 5px">H(CM)</th><th style="text-decoration: underline; padding: 5px">Weight per piece (KG)</th></tr>';
        for (var i = 0; i < order.orderDetails.length; i++) {
            mbody += "<tr><td>" + order.orderDetails[i].numberOfPieces + "</td><td>" + order.orderDetails[i]["length"] + "</td><td>" + order.orderDetails[i].width + "</td><td>" + order.orderDetails[i].height + "</td><td>" + order.orderDetails[i].weightPerPiece + "</td></tr>";
        }
        mbody += "<tr><td></td><td></td><td></td><td></td><td>Total Weight</td></tr><tr><td></td><td></td><td></td><td></td><td>" + getTotalWeight() + "</td></tr><tr><td></td><td></td><td></td><td></td><td>Chargeable Weight</td></tr><tr><td></td><td></td><td></td><td></td><td>" + getChWeight() + "</td></tr></table> <br/><br/>";
        mbody += '<Grid continer lg={12} item style={{ margin: "5px" }}><br /> Client ref: ' + (order.clientRefNumber ? order.clientRefNumber : "") + ' <br />Sub mode: ' + subModeData.find((sub) => order.subModeId == sub.id).name;
        mbody += '<br /> <br /> <b>Customer Info</b><div style="width: 100%; margin: 10px; margin-top: 0; padding: 0">Name: ' + order.consignee.companyName + '<br />  Address: ' + (order.consignee.address1 ? order.consignee.address1 : "") + ' ' + (order.consignee.address2 ? order.consignee.address2 : "") + '<br />  City: ' + (order.consignee.city ? order.consignee.city : "") + '<br />  State: ' + (order.consignee.state ? order.consignee.state : "") + '<br />  Country: ' + countryOfOriginData.find((ctr) => ctr.id == order.consignee.countryId).name + '<br />  Phone: ' + (order.consignee.phone ? order.consignee.phone : "") + ' <br />  E-Mail : ' + (order.consignee.email ? order.consignee.email : "") + '</div>';
        mbody += '<br /> <b>Shipper Info</b><div style="width: 100%; margin: 10px; margin-top: 0; padding: 0"> Name: ' + order.shipper.companyName + '<br />  Address: ' + (order.shipper.address1 ? order.shipper.address1 : "") + ' ' + (order.shipper.address2 ? order.shipper.address2 : "") + '<br />  City: ' + (order.shipper.city ? order.shipper.city : "") + '<br />  State: ' + (order.shipper.state ? order.shipper.state : "") + '<br />  Country: ' + countryOfOriginData.find((ctr) => ctr.id == order.shipper.countryId).name + ' <br />  Phone: ' + (order.shipper.phone ? order.shipper.phone : "") + ' <br />  E-Mail : ' + (order.shipper.email ? order.shipper.email : "") + '</div> </div>';
        mbody += '<div style="width:100%; display:flex; background: yellow; margin-top: 15px"><p style="margin: 10px">' + (mailBody ? mailBody : "") + "</p></div> <br/><br/> Regards.";
*/
        var mbody = '<div style="width: 100%; text-align: center"><img src="cid:header"/></div>';
        mbody += '<br /> <div style="text-align: center; width: 100%"><b>' + (order.locationId === "2" ? "Export " : "Import ") + ' JOB ORDER No. - ' + order.jobNumber + ' </b></div> <br /><br />';
        mbody += '<b> Date: </b>' + getTodayDate() + ' <br /><br /><table style="border-collapse:collapse"><tr>'
        mbody += '<td style="background: lightgrey; width: 25%"><b style="margin-right: 5px; margin-left: 5px">Shipper:</b></td>';
        mbody += '<td>' + order.shipper.companyName;
        mbody += (order.shipper.address1 ? '<br/>' : '') + (order.shipper.address1 ? order.shipper.address1 : '');
        mbody += (order.shipper.address2 ? '<br/>' : '') + (order.shipper.address2 ? order.shipper.address2 : '');
        mbody += (shipperCountry ? '<br/>' : '') + (shipperCountry ? shipperCountry : '');
        mbody += (order.shipper.city ? '<br/>' : '') + (order.shipper.city ? order.shipper.city : '');
        mbody += (order.shipper.zipCode ? '<br/>' : '') + (order.shipper.zipCode ? order.shipper.zipCode : '');
        mbody += '</td></tr><tr><td style="background: lightgrey"><b style="margin-right: 5px; margin-left: 5px">Contact:</b></td>';
        mbody += '<td>' + (order.shipper.name ? order.shipper.name : "") + '</td></tr><tr><td style="background: lightgrey"><b style="marginright: 5px; margin-left: 5px">Email:</b></td>';
        mbody += '<td>';
        mbody += (order.shipper.email ? order.shipper.email : "") + (order.shipper.email ? '<br/>' : '');
        mbody += (order.shipper.email2 ? order.shipper.email2 : "") + (order.shipper.email2 ? '<br/>' : '');
        mbody += (order.shipper.email3 ? order.shipper.email3 : "") + (order.shipper.email3 ? '<br/>' : '');
        mbody += (order.shipper.email4 ? order.shipper.email4 : "") + (order.shipper.email4 ? '<br/>' : '');
        mbody += (order.shipper.email5 ? order.shipper.email5 : "") + (order.shipper.email5 ? '<br/>' : '');
        mbody += '</td></tr><tr><td style="background: lightgrey"><b style="margin-right: 5px; margin-left: 5px">Tel:</b></td>';
        mbody += '<td>' + getShipperPhone(order.shipper) + '</td></tr></table><br /><table style="border-collapse:collapse"><tr><td style="background: lightgrey; width: 25%">';
        mbody += '<b style="marginright: 5px; margin-left: 5px">Consignee:</b></td ><td>' + order.consignee.companyName;
        mbody += (order.consignee.address1 ? '<br/>' : '') + (order.consignee.address1 ? order.consignee.address1 : '');
        mbody += (order.consignee.address2 ? '<br/>' : '') + (order.consignee.address2 ? order.consignee.address2 : '');
        mbody += (consigneeCountry ? '<br/>' : '') + (consigneeCountry ? consigneeCountry : '');
        mbody += (order.consignee.city ? '<br/>' : '') + (order.consignee.city ? order.consignee.city : '');
        mbody += (order.consignee.zipCode ? '<br/>' : '') + (order.consignee.zipCode ? order.consignee.zipCode : '');
        mbody += '</td></tr></table><br/>';
        mbody += '<b style="font-size:large; background: yellow">Shipper Status: ' + shipperStatuses.find(sh => sh.id == stageStatusInfoId)?.name + '</b><br />';
        mbody += '<b style="font-size:large; background: yellow">' + (mailBody ? getComments(mailBody) : "") + "</b><br/>";
        mbody += '<table style="width:461.25pt;margin-left:.5pt;border-collapse:collapse;border:none"><tr><td width="615" colspan="4" style="width:461.25pt;border:solid black 1.0pt;border-bottom:solid windowtext 1.0pt;background:lightgrey;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt; text-align: center;">SHIPPING INFORMATION </td></tr>';
        mbody += '<tr><td width="150" valign="top" style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Consignee Reference</td>';
        mbody += '<td width="139" valign="top" style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + (order.clientRefNumber ? order.clientRefNumber : "") + '</td><td style="background: lightgrey; border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Origin</td>';
        mbody += '<td width="154" valign="top" style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + countryOfOriginData.find((ctr) => ctr.id == order.countryOfOriginId)?.name + '</td></tr><tr>';
        mbody += '<td width="172" valign="top" style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Shipper reference</td><td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + (order.suppliersPO ? order.suppliersPO : "") + '</td><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Port of loading</td>';
        mbody += '<td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + getFrom(order.polId, order.shippingModeId, false) + '</td></tr><tr><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">No. of packs</td>';
        mbody += '<td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + getTotalPieces() + '</td><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Port of discharge</td><td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + getTo(order.poaId, order.shippingModeId) + '</td>';
        mbody += '</tr><tr><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Kind of packages</td><td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + getKindOfPieces() + '</td>';
        mbody += '<td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Shipment Method</td><td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + getType(order.shippingModeId) + '</td>';
        mbody += '</tr><tr><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Weight Kg</td><td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + getTotalWeight() + '</td>';
        mbody += '<td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Shipment Terms</td><td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + incotermsData.find(dat => dat.id == order.incotermsId)?.name + '</td>';
        mbody += '</tr><tr><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Volume CBM.</td><td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + getChWeight() + '</td><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Consignee VAT.</td>';
        mbody += '<td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + (order.consignee.uatnumber ? order.consignee.uatnumber : "") + '</td></tr><tr><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Type</td>';
        mbody += '<td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + getCargoType(subModeData.find(dat => dat.id == order.subModeId)?.name) + '</td><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt"></td><td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt"></td></tr></table>';
        mbody += "<br/><br/> Regards.<br/>" + loginUser.fullName + ", <br/>" + (validateEmail(loginUser.username) ? loginUser.username : "");

        var toList = [];
        if (mtoCust)
            toList.push(mtoCust);
        if (mtoCust2)
            toList.push(mtoCust2);
        if (mtoCust3)
            toList.push(mtoCust3);
        if (mtoCust4)
            toList.push(mtoCust4);
        if (mtoCust5)
            toList.push(mtoCust5);
        if (mtoagent)
            toList.push(mtoagent);
        if (mtoagent2)
            toList.push(mtoagent2);
        if (mtoagent3)
            toList.push(mtoagent3);
        if (mtoagent4)
            toList.push(mtoagent4);
        if (mtoagent5)
            toList.push(mtoagent5);
        if (addto)
            toList.push(addto);
        console.log(toList.toString());
        var status = 'Shipper Status: ' + shipperStatuses.find(sh => sh.id == stageStatusInfoId)?.name;
        var comments = (mailBody ? getComments(mailBody) : "");
        var mailData = { status: status, comments: comments, to: toList.toString(), subject: mailSubject, content: mbody, cc: validateEmail(loginUser.username) ? loginUser.username : "", orderId: order.id };
        sendRequest(`/api/fa/sendMail`, REQUEST_ACTIONS.POST, mailData, {
            successCallback: response => {
                if (response === "SUCCESS")
                    dispatch(snackbarToggle({ type: 'success', message: "Mail was sent successfully." }));
                else
                    dispatch(snackbarToggle({ type: 'error', message: "Mail not sent: " + response }));
            },
            failedCallback,
        });
    }
    const validateEmail = (email) => {
        if (!email)
            return false;
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const onHandleCloseModal = () => {
        dispatch(resetShipperStage());
        setOpenDialog(false);
    };

    useEffect(() => {
        return () => {
            if (order.id)
                sendRequest('/api/order/'+order.id, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {
                        var res = JSON.parse(JSON.stringify(response))
                        if (order.astatus)
                            res.astatus = JSON.parse(JSON.stringify(order.astatus));
                        if (order.cstatus)
                            res.cstatus = JSON.parse(JSON.stringify(order.cstatus));
                        updateOrder(orders, res, getOrders, dispatch);
                        //updateOrder(orders, response, getOrders, dispatch);
                    },
                    failedCallback,
                });
        }
    }, []);

    useEffect(() => {
        // predefined user abroad data get
        if (!userNLData) {
            sendRequest('/api/users/NL', REQUEST_ACTIONS.GET, '', {
                loginUser: loginUser,
                successCallback: response => {
                    onUserNLDataChange(response);
                    setNlUsers(response);
                    setNlUsersLoaded(true)
                },
                failedCallback,
            });
        } else {
            setNlUsers(userNLData);
        }
    }, [userNLData]);

    useEffect(() => {
        // predefined user abroad data get
        if (!userUSData) {
            sendRequest('/api/users/US', REQUEST_ACTIONS.GET, '', {
                loginUser: loginUser,
                successCallback: response => {
                    onUserUSDataChange(response);
                    setUSUsers(response);
                    setUSUsersLoaded(true)
                },
                failedCallback,
            });
        } else {
            setUSUsers(userUSData);
        }
    }, [userUSData]);

    useEffect(() => {
        // predefined user local data get
        if (!userILData) {
            sendRequest('/api/users/IL', REQUEST_ACTIONS.GET, '', {
                loginUser: loginUser,
                successCallback: response => {
                    onUserILDataChange(response);
                    setIlUsers(response);
                    setIlUsersLoaded(true)
                },
                failedCallback,
            });
        } else {
            setIlUsers(userILData);
        }
    }, [userILData]);

    useEffect(() => {
        if (!shipperStatuses) {
            // predefined shipper statuses data get
            sendRequest('/api/stage/2', REQUEST_ACTIONS.GET, '', {
                successCallback: response => dispatch(onShipperStatusesChange(response)),
                failedCallback,
            });
        }
    }, [dispatch, failedCallback, shipperStatuses]);

    useEffect(() => {
        setIsLoaded(!!shipperStatuses);
    }, [shipperStatuses]);

    useEffect(() => {
        const shipperStage = currentStage.shipperStage[0];

        dispatch(onFilesChange(currentStage.archives));
        dispatch(onStageStatusInfoIdChange(currentStage.stageStatusInfoId));
        dispatch(onNotesChange(currentStage.notes));

        if (shipperStage?.id) {
            dispatch(onIdChange(shipperStage.id));
            dispatch(onShipmentAddressChange(shipperStage.shipmentAddress));
            dispatch(onNextActionDateChange(shipperStage.nextActionDate));
            dispatch(onRequiredTimeSlotChange(shipperStage.requiredTimeSlot));
            dispatch(onShipperDateChange(shipperStage.estimatedPickupDate));
            dispatch(onPickupDateChange(shipperStage.pickupDate));
        }
    }, [dispatch]);

    const onStageHandleSave = id => {
        const shipperStage = [
            {
                shipmentAddress,
                requiredTimeSlot,
                estimatedPickupDate,
                nextActionDate,
                pickupDate,
            },
        ];

        if (id) {
            shipperStage[0].id = id;
        }
        const newOrder = {
            ...order,
            orderStage: [
                order.orderStage[0],
                {
                    ...currentStage,
                    notes,
                    stageStatusInfoId,
                    shipperStage,
                },
                order.orderStage[2],
                order.orderStage[3],
                order.orderStage[4],
            ],
        };
        if (newOrder.orderStage[1].stageStatusInfoId == 8) {
            if (newOrder.incotermsId != 9 && newOrder.incotermsId != 8) {
                if (!shipperStage[0].pickupDate) {
                    dispatch(snackbarToggle({ type: 'error', message: "Pickup date is required" }));
                    return;
                }
            }
        }
        sendRequest(`/api/order?fromStage=2&loginUserId=` + loginUser?.id + `&actionOn=ShipperDialog`, REQUEST_ACTIONS.PUT, newOrder, {
            successCallback: () => {
                //updateOrder(orders, newOrder, getOrders, dispatch);
                dispatch(snackbarToggle({ type: 'success', message: 'Stage saved successfully' }));

                if (order?.orderStage[1]?.stageStatusInfoId != newOrder?.orderStage[1]?.stageStatusInfoId) {
                    var chatString = getStatusChatMessage(order, 'Shipper', shipperStatuses.find(status => status.id === newOrder?.orderStage[1]?.stageStatusInfoId)?.name, ilUsers, nlUsers, usUsers, loginUser);
                    onUpdateChat(order.id, chatString, 2);
                    onHandleCloseModal();
                } else {
                    //setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
                    onHandleCloseModal();
                }
                /*sendRequest(`/api/order?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, null)}page=${page}&size=${size}`, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {
                        dispatch(snackbarToggle({ type: 'success', message: 'Stage saved successfully' }));

                        if (order?.orderStage[1]?.stageStatusInfoId != newOrder?.orderStage[1]?.stageStatusInfoId) {
                            //newOrder.chat = addStatusChatMessage(order, 'WH', 'Arrived', ilUsers, nlUsers, usUsers, loginUser);
                            var chatString = getStatusChatMessage(order, 'Shipper', shipperStatuses.find(status => status.id === newOrder?.orderStage[1]?.stageStatusInfoId)?.name, ilUsers, nlUsers, usUsers, loginUser);
                            //console.log(chatString)
                            onUpdateChat(order.id, chatString, 2)
                        } else {
                            setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
                            onHandleCloseModal();
                        }
                    },
                    failedCallback,
                });*/
            },
            failedCallback,
        });
    };



    const onUpdateChat = (id, chatString, fromStage) => {
        var data = {
            value: chatString
        }
        sendRequest(`/api/order/updateChat/${id}?fromStage=${fromStage}&loginUserId=` + loginUser?.id + `&actionOn=ShipperDialog`, REQUEST_ACTIONS.PUT, data, {
            successCallback: data => {
                //dispatch(snackbarToggle({ type: 'success', message: 'chat updated successfully' }));
                /*sendRequest(`/api/order?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, null)}page=${page}&size=${size}`, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {
                        setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
                        onHandleCloseModal();
                    },
                    failedCallback,
                });*/
            },
            failedCallback,
        });
    };

    return (
        <Dialog maxWidth='md' open={openDialog} onHandleCancel={onHandleCloseModal} title={'SHIPPER - ' + order?.jobNumber}>
            <Box component='form' noValidate autoComplete='off'>
                {!isLoaded ? (
                    <Grid container justifyContent='center' mt={2}>
                        <CircularProgress />
                    </Grid>
                ) : (
                    <Grid container spacing={2}>
                        <TextField readOnly lg={null} label='Company name' value={order.shipper.companyName || '-'} />
                        <TextField readOnly lg={null} label='Address 1' value={order.shipper.address1 || '-'} />
                        <TextField readOnly lg={null} label='p/u address' value={order.shipper.address2 || '-'} />
                        <TextField readOnly lg={null} label='Zip code' value={order.shipper.zipCode || '-'} />
                        <TextField readOnly lg={null} label='City' value={order.shipper.city || '-'} />
                        <TextField
                            readOnly
                            lg={null}
                            label='Country'
                            value={countryOfOriginData.find(country => country.id === order.shipper.countryId).name || '-'}
                        />
                        <TextField readOnly lg={null} label='Email' value={order.shipper.email || '-'} />
                        <TextField readOnly lg={null} label='Name' value={order.shipper.name || '-'} />
                        <TextField readOnly lg={null} label='Position' value={order.shipper.position || '-'} />
                        <TextField readOnly lg={null} label='Business phone' value={order.shipper.businessPhone || '-'} />
                        <TextField readOnly lg={null} label='Mobile' value={order.shipper.mobile || '-'} />
                        <TextField readOnly lg={null} label='W/H address' value={order.shipper.mobile || '-'} />
                        <TextField readOnly xs={12} sm={null} md={null} lg={null} label="Supplier's PO" value={order.suppliersPO || '-'} />
                        <Grid container item xs={12} spacing={2}>
                            <SelectField
                                required
                                disabled={!!(currentStage.status === STATUS['2'])}
                                label='Status'
                                lg={null}
                                value={stageStatusInfoId}
                                onChange={event => onHandleChange(event, onStageStatusInfoIdChange)}
                                options={shipperStatuses}
                            />
                            <DateTimePicker
                                label='Next action date'
                                value={nextActionDate}
                                onChange={date => dispatch(onNextActionDateChange(date?.toISOString()))}
                            />
                            <Button
                                disabled={!(order?.shipper?.companyName != null)}
                                variant='contained'
                                sx={{ m: 1 }}
                                onClick={onHandleOpenShipperModal}>
                                Open Shipper Card
                            </Button>
                            {openShipperModal && (
                                <Dialog
                                    maxWidth='md'
                                    open={openShipperModal}
                                    title={'Shipper Card'}
                                    onHandleCancel={onHandleCancelShipperModal}>
                                    <AddServiceProvider data={order?.shipper} onHandleCancel={onHandleCancelShipperModal} id={null} readOnly={true} />
                                </Dialog>
                            )}
                        </Grid>
                        <DateTimePicker
                            required
                            label='Required time slot'
                            value={requiredTimeSlot}
                            onChange={date => dispatch(onRequiredTimeSlotChange(date?.toISOString()))}
                        />
                        {
                            stageStatusInfoId === 8 ?
                                <DateTimePicker
                                    required
                                    label='Pick-up date'
                                    value={pickupDate}
                                    onChange={date => dispatch(onPickupDateChange(date?.toISOString()))}
                                />
                                : null
                        }
                        <MultilineField
                            xs={12}
                            sm={null}
                            md={null}
                            lg={null}
                            label='Notes'
                            value={notes}
                            onChange={event => onHandleChange(event, onNotesChange)}
                        />
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h6'>Attachments</Typography>
                        </Grid>
                        <Grid container item spacing={2}>
                            <Grid item xs={12}>
                                    <Dropzone
                                        loginUser={loginUser}
                                        orderStageId={currentStage.id}
                                        orderId={order.id}
                                        onFilesAdd={setFilesAdd}
                                        pageName={pageName}
                                        onHandleCloseParentModal={null}
                                        type={null} 
                                        />
                            </Grid>
                                <FilesRender order={order} loginUser={loginUser} files={fiileList} orderId={order.id} />
                        </Grid>
                        <Grid container item lg={12}>
                            {showMail && <Grid container item lg={12}>
                                <TextField
                                    lg={12}
                                    required
                                    dissabled
                                    style={{ padding: "5px" }}
                                    type="Text"
                                    label='Subject'
                                    value={mailSubject}
                                />
                                <Grid container item lg={12}>
                                    <Grid lg={12}>
                                        <br /> <div style={{ textAlign: "center", width: "100%" }}><b>{order.locationId === "2" ? "Export " : "Import "} JOB ORDER No. - {order.jobNumber}</b></div> <br /><br />
                                        <b>Date: </b> {getTodayDate()}<br /><br />
                                        <table style={{ width: "100%" }}>
                                                <tr>
                                                    <td style={{ background: "lightgrey", width: "25%" }}><b style={{ marginRight: "5px", marginLeft: "5px" }}>Shipper:</b></td>
                                                    <td>{order?.shipper?.companyName}
                                                        {order?.shipper?.address1 && <br></br>}{order?.shipper?.address1 ? order?.shipper?.address1 : ""}
                                                        {order?.shipper?.address2 && <br></br>}{order?.shipper?.address2 ? order?.shipper?.address2 : ""}
                                                        {shipperCountry && <br></br>}{shipperCountry ? shipperCountry : ""}
                                                        {order?.shipper?.city && <br></br>}{order?.shipper?.city ? order?.shipper?.city : ""}
                                                        {order?.shipper?.zipCode && <br></br>}{order?.shipper?.zipCode ? order?.shipper?.zipCode : ""}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ background: "lightgrey" }}><b style={{ marginRight: "5px", marginLeft: "5px" }}>Contact:</b></td>
                                                    <td>{order?.shipper?.name ? order?.shipper?.name : ""}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ background: "lightgrey" }}><b style={{ marginRight: "5px", marginLeft: "5px" }}>Email:</b></td>
                                                    <td>
                                                        {order?.shipper?.email ? order?.shipper?.email : ""}{order?.shipper?.email && <br></br>}
                                                        {order?.shipper?.email2 ? order?.shipper?.email2 : ""}{order?.shipper?.email2 && <br></br>}
                                                        {order?.shipper?.email3 ? order?.shipper?.email3 : ""}{order?.shipper?.email3 && <br></br>}
                                                        {order?.shipper?.email4 ? order?.shipper?.email4 : ""}{order?.shipper?.email4 && <br></br>}
                                                        {order?.shipper?.email5 ? order?.shipper?.email5 : ""}{order?.shipper?.email5 && <br></br>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ background: "lightgrey" }}><b style={{ marginRight: "5px", marginLeft: "5px" }}>Tel:</b></td>
                                                    <td>{getShipperPhone(order.shipper)}</td>
                                                </tr>
                                            </table>
                                            <br />
                                            <table style={{ width: "100%" }}>
                                                <tr>
                                                    <td style={{ background: "lightgrey", width: "25%" }}><b style={{ marginRight: "5px", marginLeft: "5px" }}>Consignee:</b></td>
                                                    <td>{order?.consignee?.companyName}
                                                        {order?.consignee?.address1 && <br></br>}{order?.consignee?.address1 ? order?.consignee?.address1 : ""}
                                                        {order?.consignee?.address2 && <br></br>}{order?.consignee?.address2 ? order?.consignee?.address2 : ""}
                                                        {consigneeCountry && <br></br>}{consigneeCountry ? consigneeCountry : ""}
                                                        {order?.consignee?.city && <br></br>}{order?.consignee?.city ? order?.consignee?.city : ""}
                                                        {order?.consignee?.zipCode && <br></br>}{order?.consignee?.zipCode ? order?.consignee?.zipCode : ""}
                                                    </td>
                                                </tr>
                                            </table>
                                        <Grid container item lg={12}>
                                            <br />
                                                Shipper Status: {shipperStatuses.find(sh => sh.id == stageStatusInfoId)?.name}
                                            <br />
                                            <br />
                                            Regards.
                                        </Grid>
                                        <Grid continer lg={12} item style={{ margin: "5px" }}>
                                            <MultilineField
                                                required
                                                xs={12}
                                                sm={null}
                                                md={null}
                                                lg={null}
                                                label='Comments'
                                                value={mailBody}
                                                onChange={event => setMailBody(event.target.value)}
                                            />
                                        </Grid>
                                        <table style={{ width: "100%" }}>
                                            <tr style={{ background: "lightgrey", width: "100%", textAlign: "center" }}>SHIPPING INFORMATION </tr>
                                        </table>
                                        <table style={{ width: "100%" }}>
                                            <tr>
                                                <td style={{ background: "lightgrey", width: "25%" }}>Consignee Reference</td>
                                                <td style={{ width: "25%" }}>{order.clientRefNumber}</td>
                                                <td style={{ background: "lightgrey", width: "25%" }}>Origin</td>
                                                <td style={{ width: "25%" }}>{countryOfOriginData.find((ctr) => ctr.id == order.countryOfOriginId)?.name}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ background: "lightgrey" }}>Shipper reference</td>
                                                <td>{order.suppliersPO}</td>
                                                <td style={{ background: "lightgrey" }}>Port of loading</td>
                                                <td>{getFrom(order.polId, order.shippingModeId, false)}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ background: "lightgrey" }}>No. of packs</td>
                                                <td>{getTotalPieces()}</td>
                                                <td style={{ background: "lightgrey" }}>Port of discharge</td>
                                                <td>{getTo(order.poaId, order.shippingModeId)}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ background: "lightgrey" }}>Kind of packages</td>
                                                <td>{getKindOfPieces()}</td>
                                                <td style={{ background: "lightgrey" }}>Shipment Method</td>
                                                <td>{getType(order.shippingModeId)}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ background: "lightgrey" }}>Weight Kg</td>
                                                <td>{getTotalWeight()}</td>
                                                <td style={{ background: "lightgrey" }}>Shipment Terms</td>
                                                <td>{incotermsData.find(dat => dat.id == order.incotermsId)?.name}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ background: "lightgrey" }}>Volume CBM.</td>
                                                <td>{getChWeight()}</td>
                                                <td style={{ background: "lightgrey" }}>Consignee VAT.</td>
                                                <td>{order.consignee.uatnumber}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ background: "lightgrey" }}>Type</td>
                                                    <td>{getCargoType(subModeData.find(dat => dat.id == order.subModeId)?.name)}</td>
                                                <td style={{ background: "lightgrey" }}></td>
                                                <td></td>
                                            </tr>
                                        </table>
                                    </Grid>
                                </Grid>
                                <Grid container item lg={12}>
                                    <br />
                                    Regards.
                                </Grid>
                                <Grid container lg={12} style={{ borderTop: "1px solid black", marginTop: "25px" }}>
                                    <TextField
                                        lg={12}
                                        style={{ padding: "5px", marginTop: "20px" }}
                                        type="Text"
                                        label='Additional E-Mails'
                                        value={mailToAdd}
                                        onChange={event => setMailToAdd(event.target.value)}
                                    />
                                    {!useMailToAdd && <Grid container item lg={12} style={{ color: "red" }}> * Additional emails are invalid.</Grid>}
                                </Grid>

                                <Grid container lg={12} style={{ borderTop: "1px solid black", marginTop: "25px" }}>
                                    <view style={{ marginLeft: "0", maxWidth: "500px" }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={useMailToAgent} onChange={event => useMailToAgent ? setUseMailToAgent(false) : setUseMailToAgent(true)} />
                                            }
                                            label={"Broker email: " + mailToAgent}
                                        />
                                    </view>
                                </Grid>
                                {!mailToAgentValidation && <Grid container item lg={12} style={{ color: "red" }}> * Broker email for: {brokerName} is invalid.</Grid>}

                                {mailToAgent2 && <Grid container lg={12} style={{ borderTop: "1px solid black", marginTop: "25px" }}>
                                    <view style={{ marginLeft: "0", maxWidth: "500px" }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={useMailToAgent2} onChange={event => useMailToAgent2 ? setUseMailToAgent2(false) : setUseMailToAgent2(true)} />
                                            }
                                            label={"Broker email2: " + mailToAgent2}
                                        />
                                    </view>
                                </Grid>}
                                {(mailToAgent2 && !mailToAgentValidation2) && <Grid container item lg={12} style={{ color: "red" }}> * Broker email2 for: {brokerName} is invalid.</Grid>}

                                {mailToAgent3 && <Grid container lg={12} style={{ borderTop: "1px solid black", marginTop: "25px" }}>
                                    <view style={{ marginLeft: "0", maxWidth: "500px" }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={useMailToAgent3} onChange={event => useMailToAgent3 ? setUseMailToAgent3(false) : setUseMailToAgent3(true)} />
                                            }
                                            label={"Broker email 3: " + mailToAgent3}
                                        />
                                    </view>
                                </Grid>}
                                {(mailToAgent3 && !mailToAgentValidation3) && <Grid container item lg={12} style={{ color: "red" }}> * Broker email3 for: {brokerName} is invalid.</Grid>}

                                {mailToAgent4 && <Grid container lg={12} style={{ borderTop: "1px solid black", marginTop: "25px" }}>
                                    <view style={{ marginLeft: "0", maxWidth: "500px" }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={useMailToAgent4} onChange={event => useMailToAgent4 ? setUseMailToAgent4(false) : setUseMailToAgent4(true)} />
                                            }
                                            label={"Broker email 4: " + mailToAgent4}
                                        />
                                    </view>
                                </Grid>}
                                {(mailToAgent4 && !mailToAgentValidation4) && <Grid container item lg={12} style={{ color: "red" }}> * Broker email4 for: {brokerName} is invalid.</Grid>}

                                {mailToAgent5 && <Grid container lg={12} style={{ borderTop: "1px solid black", marginTop: "25px" }}>
                                    <view style={{ marginLeft: "0", maxWidth: "500px" }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={useMailToAgent5} onChange={event => useMailToAgent5 ? setUseMailToAgent5(false) : setUseMailToAgent5(true)} />
                                            }
                                            label={"Broker email5: " + mailToAgent5}
                                        />
                                    </view>
                                </Grid>}
                                {(mailToAgent5 && !mailToAgentValidation5) && <Grid container item lg={12} style={{ color: "red" }}> * Broker email5 for: {brokerName} is invalid.</Grid>}

                                    {order?.consignee?.email && <Grid container lg={12}>
                                        <view style={{ marginTop: "10px", marginLeft: "0", maxWidth: "500px" }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={useMailToCustomer} onChange={event => useMailToCustomer ? setUseMailToCustomer(false) : setUseMailToCustomer(true)} />
                                                }
                                                label={"Consignee email: " + order?.consignee?.email}
                                            />
                                        </view>
                                    </Grid>}
                                    {(order?.consignee?.email && !mailToCustomerValidation) && <Grid container item style={{ color: "red" }}>* Consignee email for: {order.consignee.companyName} is invalid.</Grid>}

                                    {order?.consignee?.email2 && <Grid container lg={12}>
                                        <view style={{ marginTop: "10px", marginLeft: "0", maxWidth: "500px" }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={useMailToCustomer2} onChange={event => useMailToCustomer2 ? setUseMailToCustomer2(false) : setUseMailToCustomer2(true)} />
                                                }
                                                label={"Consignee email2: " + order?.consignee?.email2}
                                            />
                                        </view>
                                    </Grid>}
                                    {(order?.consignee?.email2 && !mailToCustomerValidation2) && <Grid container item style={{ color: "red" }}>* Consignee email2 for: {order.consignee.companyName} is invalid.</Grid>}

                                    {order?.consignee?.email3 && <Grid container lg={12}>
                                        <view style={{ marginTop: "10px", marginLeft: "0", maxWidth: "500px" }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={useMailToCustomer3} onChange={event => useMailToCustomer3 ? setUseMailToCustomer3(false) : setUseMailToCustomer3(true)} />
                                                }
                                                label={"Consignee email3: " + order.consignee.email3}
                                            />
                                        </view>
                                    </Grid>}
                                    {(order?.consignee?.email3 && !mailToCustomerValidation3) && <Grid container item style={{ color: "red" }}>* Consignee email3 for: {order.consignee.companyName} is invalid.</Grid>}

                                    {order?.consignee?.email4 && <Grid container lg={12}>
                                        <view style={{ marginTop: "10px", marginLeft: "0", maxWidth: "500px" }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={useMailToCustomer4} onChange={event => useMailToCustomer4 ? setUseMailToCustomer4(false) : setUseMailToCustomer4(true)} />
                                                }
                                                label={"Consignee email 4: " + order.consignee.email4}
                                            />
                                        </view>
                                    </Grid>}
                                    {(order?.consignee?.email4 && !mailToCustomerValidation4) && <Grid container item style={{ color: "red" }}>* Consignee email4 for: {order.consignee.companyName} is invalid.</Grid>}

                                    {order?.consignee?.email5 && <Grid container lg={12}>
                                        <view style={{ marginTop: "10px", marginLeft: "0", maxWidth: "500px" }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={useMailToCustomer5} onChange={event => useMailToCustomer5 ? setUseMailToCustomer5(false) : setUseMailToCustomer5(true)} />
                                                }
                                                label={"Consignee email 5: " + order.consignee.email5}
                                            />
                                        </view>
                                    </Grid>}
                                    {(order?.consignee?.email5 && !mailToCustomerValidation5) && <Grid container item style={{ color: "red" }}>* Consignee email5 for: {order.consignee.companyName} is invalid.</Grid>}
                                <Grid container lg={12}>
                                    <Button
                                        variant='contained'
                                        style={{ margin: "15px", marginLeft: "0", maxWidth: "150px" }}
                                        onClick={sendMail}>
                                        Send Mail
                                    </Button>
                                </Grid>
                            </Grid>}
                        </Grid>
                        <Grid container item xs={12} justifyContent='flex-end'>
                            <Grid container lg={6}>
                                <Button
                                    variant='contained'
                                    style={{ margin: "15px", marginLeft: "0", maxWidth: "150px" }}
                                    onClick={setShowMail}>
                                    Mail
                                </Button>
                            </Grid>
                                <Grid container lg={6} justifyContent="right" alignItems="center">
                                <Button
                                        variant='contained'
                                        style={{ marginRight: "16px", maxHeight: "37px" }}
                                    onClick={id ? () => onStageHandleSave(id) : () => onStageHandleSave(null)}>
                                    Save
                                </Button>
                                    <Button variant='contained' color='secondary'
                                        style={{ marginRight: 2, maxHeight: "37px" }} onClick={onHandleCloseModal}>
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Box>
        </Dialog>
    );
};

export default ShipperDialog;
