/* eslint-disable react/prop-types */
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Box, Button, Grid, InputAdornment, CircularProgress } from "@mui/material";
import { TextField } from 'style-guide';
import './CustomerDashboard.scss';
import CircleDotIcon from "../../../resources/circledot_icon.svg"
import InsightIcon from "../../../resources/insight_icon.svg"
import DefaultIcon from "../../../resources/default_icon.svg"
import AddIcon from "../../../resources/add_icon.svg"
import TitleBar from "../common/TitleBar";
import ShipmentDataRow from "../common/shipmentDataRow/ShipmentDataRow";
import ShipmentDataMap from "../common/shipmentDataMap/ShipmentDataMap";
import { useDispatch } from "react-redux";
import { REQUEST_ACTIONS, sendRequest, getCustomerData } from 'utils/Communicator';
import { addOrderFilter } from "Utils";
import { snackbarToggle } from "reducers/snackbarSlicer";
import { onOrdersCountChange } from "reducers/order-reducers/ordersSlicer";
import ShipmentDetailDialog from "../common/shipmentDetailDialog/ShipmentDetailDialog";
import { TABS } from "constants/global-constants";

import globeIcon from "../../../resources/globe.jpg"
import fclIcon from "../../../resources/lclmap.png"
import lclIcon from "../../../resources/lclmap.png"
import airIcon from "../../../resources/airmapblue.png"
import fileArrowDown from "../../../resources/FileArrowDown.png"
import worldMap from "../../../resources/world.png"
import SearchIcon from '@mui/icons-material/Search';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import * as XLSX from 'xlsx';

import {
    useJsApiLoader,
    GoogleMap,
    Marker,
    Polyline,
    InfoWindow,

} from '@react-google-maps/api'
import {
    setDefaults,
    geocode,
    RequestType,
} from "react-geocode";

const CustomerDashboard = ({ loginUser, jobSearch, setJobSearch, mapDisplay, setMapDisplay }) => {
    const dispatch = useDispatch();
    const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const [isShipmentList, setisShipmentList] = useState(true);
    const [isShipmentList1, setisShipmentList1] = useState(false);
    // const { orders, page, size, ordersCount } = useSelector(state => state.orders);
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);

    const [orderList, setOrderList] = useState([]);
    const [orderL, setOrderL] = useState([]);
    const [mapLocations, setMapLocations] = useState([]);
    const [isOrderDetails, setIsOrderDetails] = useState(false);
    const [activeTab, setActiveTab] = useState(undefined);
    const [selectedOrder, setSelectedOrder] = useState('');

    const [selectedPlace, setSelectedPlace] = useState(null);
    const [infoOpen, setInfoOpen] = useState(false);
    const [mapRef, setMapRef] = useState(null);
    const [mapX, setMapX] = useState({ lat: 32, lng: 34 });
    const [mapZoom, setMapZoom] = useState(1);
    const [shipperInitItems, setShipperInitItems] = useState(0);
    const [shipperItems, setShipperItems] = useState(0);
    const [truckerItems, setTruckerItems] = useState(0);
    const [departureItems, setDepartureItems] = useState(0);
    const [podItems, setPODItems] = useState(0);
    const [customsItems, setCustomsItems] = useState(0);
    const [importedItems, setImportedItems] = useState(0);
    const [libraries] = useState(['places']);
    const [markerMap, setMarkerMap] = useState({});
    const [incotermsData, setIncotermsData] = useState(null);
    const [subMode, setSubMode] = useState(null);
    const [polAir, setPolAir] = useState(null);
    const [podAir, setPodAir] = useState(null);
    const [polSea, setPolSea] = useState(null);
    const [podSea, setPodSea] = useState(null);
    const [sMode, setSMode] = useState(null);
    const [fclType20Data, setFclType20Data] = useState(null);
    const [fclType40Data, setFclType40Data] = useState(null);
    const [cOrigin, setCOrigin] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        const params = [];
        let size = 1;

        setVarInterval(setInterval(() => {
            getCustomerData(loginUser, failedCallback, setOrderList1, true);
        }, 30000));
        // predefined air poa data get

        sendRequest('/api/fclType40', REQUEST_ACTIONS.GET, '', {
            successCallback: response => setFclType40Data(response),
            failedCallback,
        });
        sendRequest('/api/fclType20', REQUEST_ACTIONS.GET, '', {
            successCallback: response => setFclType20Data(response),
            failedCallback,
        });
        sendRequest('/api/subMode', REQUEST_ACTIONS.GET, '', {
            successCallback: response => setSubMode(response),
            failedCallback,
        });


        sendRequest('/api/poa/air', REQUEST_ACTIONS.GET, '', {
            successCallback: response => setPodAir(response),
            failedCallback,
        });

        // predefined air pol data get
        sendRequest('/api/pol/air', REQUEST_ACTIONS.GET, '', {
            successCallback: response => setPolAir(response),
            failedCallback,
        });

        // predefined lcl poa data get
        sendRequest('/api/poa/lcl', REQUEST_ACTIONS.GET, '', {
            successCallback: response => setPodSea(response),
            failedCallback,
        });

        // predefined lcl pol data get
        sendRequest('/api/pol/lcl', REQUEST_ACTIONS.GET, '', {
            successCallback: response => setPolSea(response),
            failedCallback,
        });

        // predefined shipping mode data get
        sendRequest('/api/subMode', REQUEST_ACTIONS.GET, '', {
            successCallback: response => setSMode(response),
            failedCallback,
        });
        sendRequest('/api/incoterms', REQUEST_ACTIONS.GET, '', {
            successCallback: response => setIncotermsData(response),
            failedCallback,
        });

        console.log("Customer Dashboard use effect");
        // predefined country of origin data get
        sendRequest('/api/countryOfOrigin', REQUEST_ACTIONS.GET, '', {
            successCallback: response => setCOrigin(response),
            failedCallback,
        });
        setIsLoading(true);
        getCustomerData(loginUser, failedCallback, setOrderList2, true);
    }, [])
    useEffect(() => {
        var orders = setOrderList1(orderList);
        orders.sort(function (a, b) { return b.date - a.date });
        orders = orders.filter(order => !order?.historyFlag);
        var ordersfiltered = orders.filter(order => !order.historyFlag);
        if (jobSearch)
            ordersfiltered = orders.filter(order => order?.searchText?.includes(jobSearch) && !order.historyFlag);
        setOrderL(ordersfiltered);
    }, [jobSearch])
    useEffect(() => {
        if (mapRef && orderList) {
            fitBounds(mapRef, orderList)
        }
        var orders = orderList;
        orders.sort(function (a, b) { return b.date - a.date });
        orders = orders.filter(order => !order?.historyFlag);
        var ordersfiltered = orders.filter(order => !order.historyFlag);
        if (jobSearch)
            ordersfiltered = orders.filter(order => order?.searchText?.includes(jobSearch) && !order.historyFlag);
        setOrderL(ordersfiltered);
    }, [orderList])
    const [varInterval, setVarInterval] = useState();
    setDefaults({
        key: "AIzaSyDgpzrxExQg_AUW8h199onppaSz72SYlSY", // Your API key here.
        language: "en", // Default language for responses.
        region: "us", // Default region for responses.
    });
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDgpzrxExQg_AUW8h199onppaSz72SYlSY",
        libraries,
    });
    const fitBounds = (map, orders) => {
        if (!map)
            return;
        const bounds = new window.google.maps.LatLngBounds(
            new google.maps.LatLng(35, 32),
            new google.maps.LatLng(36.8363969256, 35)
        );
        orders.map(order => {
            if (order.mapLocation?.lat && order.mapLocation?.lng) bounds.extend(order.mapLocation);
                return order.id;
        });
        map.fitBounds(bounds);
    };
    const loadHandler = map => {
        setMapRef(map);
        fitBounds(map, orderList);
    };
    const markerLoadHandler = (marker, place) => {
        return setMarkerMap(prevState => {
            return { ...prevState, [place.id]: marker };
        });
    };
    const markerClickHandler = (event, place) => {
        setSelectedPlace(place);

        if (infoOpen) {
            setInfoOpen(false);
        }else
            setInfoOpen(true);
    };

    const getOriginTime = (airStatus) => {
        var origin = airStatus?.originAndDestination?.origin;
        var time = null;
        airStatus.flights.forEach(item => {
            if (item.origin === origin) {
                time = item.atd;
            }
        });
        return time;
    }
    const getDestinationTime = (airStatus) => {
        var destination = airStatus?.originAndDestination?.destination;
        var time = null;
        airStatus?.flights.forEach(item => {
            if (item.destination === destination) {
                time = item.ata;
            }
        });
        return time;
    }
    const getPodStatus = (airStatus, status) => {
        var destination = airStatus?.originAndDestination?.destination;
        var time = null;
        airStatus?.flights?.forEach(item => {
            if (item.destination === destination) {
                if (item.ata)
                    return "SUCCESS";
            }
        });
        if (status === "SUCCESS")
            return "IN_PROGRESS";
        return "NOT_STARTED"
    }
    const getCustomStatus = (order) => {
        if (order.shippingModeId == 1) {
            return order.airStatus?.lStatus ? order.airStatus?.lStatus : "";
        } else {
            return getTaskyamStatus(order.taskyam?.data?.status)
        }
    }
    const getFCLType = (order) => {
        var res = null;
        var res1 = null;
        if (order.orderFCLDetails?.fclType20Id) {
            res = fclType20Data.find(fcl => fcl.id == order.orderFCLDetails?.fclType20Id);
            if (order.orderFCLDetails?.fclType40Id) {
                res1 = fclType40Data.find(fcl => fcl.id == order.orderFCLDetails?.fclType40Id);
            }
        }
        else if (order.orderFCLDetails?.fclType40Id) {
            res = fclType40Data.find(fcl => fcl.id == order.orderFCLDetails?.fclType40Id);
        }
        return res ? res.name + (res1 ? (", " + res1.name) : "") : "-"
    }
    const getSinayStatus = (str, status) => {
        var msg = status?.val?.message ? status?.val?.message : "";
        if (msg) {
            return msg;
        }
        return str;
    }
    const getTaskyamStatus = (id) => {
        switch (id) {
            case 0:
                return "";
            case 1:
                return "מצהר";
            case 2:
                return "זמינות";
            case 3:
                return "פקודת מסירה";
            case 4:
                return "בדיקת מכס";
            case 5:
                return "התרת מכס";
            case 6:
                return "התרת נמל";
            case 7:
                return "תעודת ליווי";
            case 8:
                return "בקשה להוצאה";
            case 9:
                return "משוב מהנמל";
            case 10:
                return "יציאת מטען";
        }
        return "-"
    }
    const getCountryName = (order) => {
        var cname = cOrigin?.find(ctr => ctr.id == order.countryOfOriginId);
        return cname ? ", " + cname.name : "";
    }
    const getPOD = (order) => {
        var custDate = order.airStatus?.podDate ? order.airStatus?.podDate : (order.orderStage[4]?.departureStage[0]?.etd ? order.orderStage[4]?.departureStage[0].etd : null)
        if (!custDate)
            return "";
        var dt = order?.sinay?.arDate1 ? order.sinay.arDate1 : custDate;
        return ", " + formatDateTime(dt);
    }
    const formatDateTime = (date) => {
        if (!date)
            return "-";
        var dt = new Date(date);
        const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
        return `${padL(dt.getDate())}/${padL(dt.getMonth() + 1)}/${dt.getFullYear().toString().substr(-2)}`;
    }
    const getPOLStatus = (order) => {
        if (order.orderStatus[3].status === "SUCCESS" || getLastStatusNum(order) >= 3) {
            if (order.orderStatus[5].status) {
                if (order.orderStatus[5].status === "SUCCESS")
                    return "done";
                else (order.orderStatus[5].status === "IN_PROGRESS")
                return "inprogress";
            }
            else
                return "inprogress";
        }
        else
            return ""
    }
    const getPODStatus = (order) => {
        if (order.orderStatus[4].status === "SUCCESS") {
            if (order.orderStatus[5].status === "SUCCESS") {
                if (order.orderStatus[6].status) {
                    if (order.orderStatus[6].status === "SUCCESS")
                        return "done";
                    else if (order.orderStatus[6].status === "IN_PROGRESS")
                        return "inprogress";
                    else return "";
                }
                else
                    return "inprogress";
            }
            else
                return "";
        }
        else
            return "";
    }
    const getCustomsStatus = (order) => {
        if (order.orderStatus[4].status === "SUCCESS" && order.orderStatus[5].status === "SUCCESS" && order.orderStatus[6].status === "SUCCESS") {
            if (order.orderStatus[7].status === "SUCCESS") {
                return "done";
            } else if (order.orderStatus[7].status === "IN_PROGRESS") {
                return "inprogress";
            } else
                return "";
        }
        else
            return ""
    }
    const getImportStatus = (order) => {
        if (order.orderStatus[4].status === "SUCCESS" && order.orderStatus[5].status === "SUCCESS" && order.orderStatus[6].status === "SUCCESS" && order.orderStatus[7].status === "SUCCESS") {
            if (order.orderStatus[8].status === "SUCCESS") {
                return "done";
            } else if (order.orderStatus[8].status === "IN_PROGRESS") {
                return "inprogress";
            } else
                return "";
        }
        else
            return ""
    }
    const getLastStatusNum = (order) => {
        if (order.orderStatus[8]?.status === "IN_PROGRESS" || order.orderStatus[8]?.status === "FAILED")
            return 6;
        if (order.orderStatus[7]?.status === "IN_PROGRESS" || order.orderStatus[7]?.status === "FAILED")
            return 5;
        if (order.orderStatus[6]?.status === "IN_PROGRESS" || order.orderStatus[6]?.status === "FAILED")
            return 4;
        if (order.orderStatus[5]?.status === "IN_PROGRESS" || order.orderStatus[5]?.status === "FAILED")
            return 3;
        if (order.orderStage[4]?.status === "IN_PROGRESS" || order.orderStage[4]?.status === "FAILED")
            return 2;
        if (order.orderStage[3]?.status === "IN_PROGRESS" || order.orderStage[3]?.status === "FAILED")
            return 2;
        if (order.orderStage[2]?.status === "IN_PROGRESS" || order.orderStage[2]?.status === "FAILED")
            return 1;
        if (order.orderStage[1]?.status === "IN_PROGRESS" || order.orderStage[1]?.status === "FAILED")
            return 1;
        if (order.orderStage[0]?.status === "IN_PROGRESS" || order.orderStage[0]?.status === "FAILED")
            return 1;
    }
    const getLastRoute = (order) => {
        if ("inprogress" === ((order.orderStatus[1].status === 'SUCCESS' || getLastStatusNum(order) > 1) ? 'done' : 'inprogress'))
            return 'Shipper'
        if ("inprogress" === ((order.orderStatus[3].status === 'SUCCESS' || getLastStatusNum(order) > 2) ? 'done' : (order.orderStatus[1].status === 'SUCCESS' ? 'inprogress' : '')))
            return 'Warehouse';
        if (getPOLStatus(order) === "inprogress")
            return "Departure";
        if (getPODStatus(order) === "inprogress")
            return "Destination";
        if (getCustomsStatus(order) === "inprogress")
            return "Customs";
        if (getImportStatus(order) === "inprogress")
            return "Delivered";

        if (order.orderStatus[8]?.status === "IN_PROGRESS" || order.orderStatus[8]?.status === "FAILED")
            return "Delivered";
        if (order.orderStatus[7]?.status === "IN_PROGRESS" || order.orderStatus[7]?.status === "FAILED")
            return "Customs";
        if (order.orderStatus[6]?.status === "IN_PROGRESS" || order.orderStatus[6]?.status === "FAILED")
            return "Destination";
        if (order.orderStatus[5]?.status === "IN_PROGRESS" || order.orderStatus[5]?.status === "FAILED")
            return "Departure";
        if (order.orderStage[4]?.status === "IN_PROGRESS" || order.orderStage[4]?.status === "FAILED")
            return "Warehouse";
        if (order.orderStage[3]?.status === "IN_PROGRESS" || order.orderStage[3]?.status === "FAILED")
            return "Warehouse";
        if (order.orderStage[2]?.status === "IN_PROGRESS" || order.orderStage[2]?.status === "FAILED")
            return "Shipper";
        if (order.orderStage[1]?.status === "IN_PROGRESS" || order.orderStage[1]?.status === "FAILED")
            return "Shipper";
        if (order.orderStage[0]?.status === "IN_PROGRESS" || order.orderStage[0]?.status === "FAILED")
            return "Shipper";
    }
    const setOrderList1 = (orders) => {
        for (var i = 0; i < orders.length; i++) {
            //var ml = mapLocations.filter(m => m.id == orders[i].id);
            /*if (!ml?.mapLocation && orders[i].mapAddress && !orders[i].historyFlag) {
                orders[i].mapLocation = await geocode(RequestType.ADDRESS, orders[i].mapAddress, {
                    language: "en",
                });
                orders[i].mapLocation = orders[i].mapLocation.results[0].geometry.location;
            }*/
            var port = orders[i].shippingModeId == 1 ? podAir?.find(air => air.id == orders[i].poaId)?.name : podSea?.find(sea => sea.id == orders[i].poaId)?.name;
            var searchText = orders[i].jobNumber + ", " + orders[i].suppliersPO;
            searchText += (orders[i].shipper?.companyShortName ? ", " + orders[i].shipper.companyShortName : "");
            searchText += orders[i].shippingModeId == 3 ? ", FCL" : (orders[i].shippingModeId == 2 ? ", LCL" : ", AIR");
            searchText += getCountryName(orders[i]) + (port ? ", " + port : "") + getPOD(orders[i]) + ", " + getLastRoute(orders[i]);
            orders[i].searchText = searchText;
        }
       /* orders.sort(function (a, b) { return b.date - a.date });
        orders = orders.filter(order => !order?.historyFlag);
        var ordersfiltered = orders.filter(order => !order.historyFlag);
        if (jobSearch)
            ordersfiltered = orders.filter(order => order?.searchText?.includes(jobSearch) && !order.historyFlag);
        setOrderL(ordersfiltered);
        fitBounds(mapRef, orders);*/
        setOrderList(orders);
        setIsLoading(false);
        setisShipmentList1(true);
        return orders;
        /*var mls = JSON.parse(JSON.stringify(mapLocations));
        for (var i = 0; i < orders.length; i++) {
            if (orders[i].mapLocation) {
                mls.push({ id: JSON.parse(JSON.stringify(orders[i].id)), mapLocation: JSON.parse(JSON.stringify(orders[i].mapLocation)) });
            }
        }
        setMapLocations(JSON.parse(JSON.stringify(mls)));*/
    }
    const setOrderList2 = (orders) => {
        var mapLocationExists = false;
        for (var i = 0; i < orders.length; i++) {
            //var ml = mapLocations.filter(m => m.id == orders[i].id);
            /*if (!ml?.mapLocation && orders[i].mapAddress && !orders[i].historyFlag) {
                orders[i].mapLocation = await geocode(RequestType.ADDRESS, orders[i].mapAddress, {
                    language: "en",
                });
                orders[i].mapLocation = orders[i].mapLocation.results[0].geometry.location;
            }*/
            if (orders[i].mapLocation)
                mapLocationExists = true;
            var port = orders[i].shippingModeId == 1 ? podAir?.find(air => air.id == orders[i].poaId)?.name : podSea?.find(sea => sea.id == orders[i].poaId)?.name;
            var searchText = orders[i].jobNumber + ", " + orders[i].suppliersPO;
            searchText += (orders[i].shipper?.companyShortName ? ", " + orders[i].shipper.companyShortName : "");
            searchText += orders[i].shippingModeId == 3 ? ", FCL" : (orders[i].shippingModeId == 2 ? ", LCL" : ", AIR");
            searchText += getCountryName(orders[i]) + (port ? ", " + port : "") + getPOD(orders[i]) + ", " + getLastRoute(orders[i]);
            orders[i].searchText = searchText;
        }
        setMapDisplay(mapLocationExists);
        orders.sort(function (a, b) { return b.date - a.date });
        orders = orders.filter(order => !order?.historyFlag);
        var ordersfiltered = orders.filter(order => !order.historyFlag);
        if (jobSearch)
            ordersfiltered = orders.filter(order => order?.searchText?.includes(jobSearch) && !order.historyFlag);
        setOrderL(ordersfiltered);
        fitBounds(mapRef, orders);
        setOrderList(orders);
        setIsLoading(false);
        setisShipmentList1(true);
        /*var mls = JSON.parse(JSON.stringify(mapLocations));
        for (var i = 0; i < orders.length; i++) {
            if (orders[i].mapLocation) {
                mls.push({ id: JSON.parse(JSON.stringify(orders[i].id)), mapLocation: JSON.parse(JSON.stringify(orders[i].mapLocation)) });
            }
        }
        setMapLocations(JSON.parse(JSON.stringify(mls)));*/
    }
    const stringToColour = (ind, len) => {
        var red = 255 * (ind + 1) / len;
        var green = 255 * (len - ind - 1) / len;
        var blue = 255;
        var r = red.toString(16).toUpperCase();
        var g = green.toString(16).toUpperCase();
        var b = blue.toString(16).toUpperCase();
        return "#" + r + g + b;
    }


    const Il_Time = new Date().toLocaleString("en-EN", {timeZone: 'Israel'});
    const Us_Time = new Date().toLocaleString("en-EN", {timeZone: 'America/Los_Angeles'});
    const Gr_Time = new Date().toLocaleString("en-EN", {timeZone: 'Europe/Berlin'});

    const IL_Time = new Date(Il_Time);
    const US_Time = new Date(Us_Time);
    const GR_Time = new Date(Gr_Time);

    function tConvert (time) {
        // Check correct time format and split into components
        time = time.toString().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) { // If time format correct
          time = time.slice (1);  // Remove full string match value
        //   time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
          time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join (''); // return adjusted time or original string
    }

    const timeZoneData = [
        {
            country: 'Israel',
            zone: 'TLV',
            time: tConvert(`${IL_Time.getHours()}:${IL_Time.getMinutes()}`),
            meridiem: 'AM',
            weather: 'Sunny',
            temp: '23',
        },
        {
            country: 'USA',
            zone: 'LA',
            time: tConvert(`${US_Time.getHours()}:${US_Time.getMinutes()}`),
            meridiem: 'AM',
            weather: 'Clear',
            temp: '30',
        },
        {
            country: 'German',
            zone: 'Humburg',
            time: tConvert(`${GR_Time.getHours()}:${GR_Time.getMinutes()}`),
            meridiem: 'PM',
            weather: 'Haze',
            temp: '28',
        }
    ];

    // Order Details dialog handle function with active tab
    // @params order, tab name(active)
    const orderDetailHandle = (order, tab = TABS[1]) => {
        if (order?.id) {
            setSelectedOrder(order);
            setActiveTab(tab);
            setIsOrderDetails(!isOrderDetails);
        }
    };

    const getLastEvent = (order) => {
        if (order.orderStatus[8]?.status === "IN_PROGRESS" || order.orderStatus[8]?.status === "FAILED")
            return "Delivery to Consignee";
        if (order.orderStatus[7]?.status === "IN_PROGRESS" || order.orderStatus[7]?.status === "FAILED")
            return "Customs - " + getCustomStatus(order);
        if (order.orderStatus[6]?.status === "IN_PROGRESS" || order.orderStatus[6]?.status === "FAILED")
            return "POD - " + getPodStatus(order);
        if (order.orderStatus[5]?.status === "IN_PROGRESS" || order.orderStatus[5]?.status === "FAILED")
            return "POL - " + getPodStatus(order);
        if (order.orderStage[4]?.status === "IN_PROGRESS" || order.orderStage[4]?.status === "FAILED")
            return "Departure Waiting";
        if (order.orderStage[3]?.status === "IN_PROGRESS" || order.orderStage[3]?.status === "FAILED")
            return "In Warehouse";
        if (order.orderStage[2]?.status === "IN_PROGRESS" || order.orderStage[2]?.status === "FAILED")
            return "In Trucking";
        if (order.orderStage[1]?.status === "IN_PROGRESS" || order.orderStage[1]?.status === "FAILED")
            return "In Shipper";
        if (order.orderStage[0]?.status === "IN_PROGRESS" || order.orderStage[0]?.status === "FAILED")
            return "Need Confirmation";
    }
    const jsonToExcel = function (data, name, header) {
        var ws = XLSX.utils.json_to_sheet(data, header);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "All");
        XLSX.writeFile(wb, name + ".xlsx");
    }
    const exportShipments = () => {
        var reap = orderList;
        var data_main = [];
        var header = ["Type", "File", "JOB", "PO", "Supplier Name", "QTY", "Package", "Weight", "Volume", "INCOTERMS", "Origin Country", "Load Port", "Discharge Port", "ETA", "Vessel name", "Container", "Stage", "File Number"];
        for (var i = 0; i < reap.length; i++) {
            var data = {
                "Type": reap[i].locationId == 2 ? "Export" : "Import",
                "File": reap[i]?.orderStage[4]?.departureStage[0]?.fileNumber ? reap[i]?.orderStage[4]?.departureStage[0]?.fileNumber : "-",
                "JOB": reap[i].jobNumber ? reap[i].jobNumber : "-",
                "PO": reap[i]?.clientRefNumber ? reap[i]?.clientRefNumber : "-",
                "Sub Mode": subMode?.find(mode => mode.id === reap[i].subModeId)?.name || '-',
                "Supplier Name": reap[i]?.shipper?.companyName || '-',
                "QTY": getOrderQuantity(reap[i]),
                "Package": reap[i].shippingModeId == 3 ? getFCLType(reap[i]) : "PACKAGES",
                "Weight": getOrderWeight(reap[i]),
                "Volume": getOrderVolume(reap[i]),
                "INCOTERMS": incotermsData.find(inco => inco.id == reap[i]?.incotermsId).name || '-',
                "Origin Country": cOrigin?.find(country => country.id === reap[i].countryOfOriginId)?.name,
                "Load Port": ((reap[i]?.shippingModeId === 1) ? (polAir?.find(pol => pol.id === reap[i].polId)?.name) : (polSea?.find(pol => pol.id === reap[i].polId)?.name)) || '-',
                "Discharge Port": ((reap[i]?.shippingModeId === 1) ? (podAir?.find(poa => poa.id === reap[i].poaId)?.name) : (podSea?.find(poa => poa.id === reap[i].poaId)?.name)) || '-',
                //"ETD": formatDateTime(reap[i]?.orderStage[4]?.departureStage[0]?.etd),
                "ETA": formatDateTime(reap[i].airStatus?.podDate ? reap[i].airStatus?.podDate : (reap[i].orderStage[4]?.departureStage[0]?.eta ? reap[i].orderStage[4]?.departureStage[0].eta : null)),
                "Vessel name": reap[i]?.orderStage[4]?.departureStage[0]?.vesselNumber ? reap[i]?.orderStage[4]?.departureStage[0]?.vesselNumber : "-",
                "Container": reap[i]?.orderStage[4]?.departureStage[0]?.containerNumber ? reap[i]?.orderStage[4]?.departureStage[0]?.containerNumber : "-",
                //"AWB": reap[i]?.shippingModeId === 1 ? (reap[i]?.orderStage[4]?.departureStage[0]?.awb ? reap[i]?.orderStage[4]?.departureStage[0]?.awb : "-") : "-",
                //"Flight": reap[i]?.orderStage[4]?.departureStage[0]?.flightNumber ? reap[i]?.orderStage[4]?.departureStage[0]?.flightNumber : "-",
                "Stage": getLastRoute(reap[i]),
                "File Number": reap[i].fileNumber,
            };

            data_main.push(data);
        }
        jsonToExcel(data_main, "orders", header);
    }
    const getOrderQuantity = (order) => {
        var res = 0;
        if (order.orderDetails) {
            for (var i = 0; i < order.orderDetails.length; i++) {
                res += order.orderDetails[i].numberOfPieces;
            }
        }
        if (order.orderFCLDetails?.quantity40) {
            res += order.orderFCLDetails.quantity40
        }
        if (order.orderFCLDetails?.quantity20) {
            res += order.orderFCLDetails.quantity20
        }
        return res;
    }
    const getOrderWeight = (order) => {
        var res = 0;
        if (order.orderDetails) {
            for (var i = 0; i < order.orderDetails.length; i++) {
                res += order.orderDetails[i].weightPerPiece;
            }
        }
        return res;
    }
    const getOrderVolume = (order) => {
        var res = 0;
        if (order.orderDetails) {
            for (var i = 0; i < order.orderDetails.length; i++) {
                res += (order.orderDetails[i].height * order.orderDetails[i].width * order.orderDetails[i]["length"]) / 1000000;
            }
        }
        return res;
    }


    const activeShipmentsActions = () => (
        <Fragment>
            {!isMobile() ? <Grid item container flexWrap="nowrap"><Button style={{marginBottom:"2vw"}} title="" className="icon-btn" onClick={() => exportShipments()}>
                <img src={fileArrowDown} />
                </Button></Grid> :
                <Grid item container flexWrap="nowrap" style={{ background: "#fff" }}>
                    
                </Grid>
            }
        </Fragment>);
    const activeShipmentsActionsExport = () => (
        <Fragment>
            {isMobile() && <Grid item container flexWrap="nowrap" style={{ background: "#fff" }}>
                    <TextField
                        xs={12}
                        sm={null}
                        md={null}
                        lg={null}
                        style={{ width: "100%" }}
                        label=''
                        type='search'
                        className="search"
                        value={jobSearch}
                        endAdornment={
                            <InputAdornment position='end'>
                                <SearchIcon onClick={() => setOrderList1(orderList)} />
                            </InputAdornment>
                        }
                        onChange={event => setJobSearch(event.target.value)}
                    />
                </Grid>
            }
        </Fragment>);

    const isExportInList = (oList) => {
        if (oList) {
            if (oList.length > 0) {
                for (var i = 0; i < oList.length; i++) {
                    if (oList[i].locationId == 2)
                        return 1;
                }
            }
            return 0;
        }
        return 0;
    }
    const isImportInList = (oList) => {
        if (oList) {
            if (oList.length > 0) {
                for (var i = 0; i < oList.length; i++) {
                    if (oList[i].locationId != 2)
                        return 1;
                }
            }
            return 0;
        }
        return 0;
    }
    const getMapLocation = (olist, item, mls) => {
        if (item.mapLocation)
            return item.mapLocation;
        for (var i = 0; i < mls.length; i++) {
            if (mls[i].id == item.id)
                return mls[i].mapLocation;
        }
        return null;
    }

    return (
        <Fragment class="customer-dashboard-root">
            {(isShipmentList1 && mapDisplay) && <Grid className="googleMapDimentions" lg={12} item container >
                { <Grid md={12} item container>
                        {/*center={mapX}
                        zoom={mapZoom}*/}
                    {(isLoaded && mapDisplay) && <GoogleMap
                        mapContainerStyle={{ width: '100%', height: '100%' }}
                        options={{
                            zoomControl: false,
                            streetViewControl: false,
                            mapTypeControl: false,
                            fullscreenControl: false,
                        }}
                        onLoad={loadHandler}
                    >
                        {orderList.map((item, index) => (
                            getMapLocation(orderList, item, mapLocations) ?
                                <Marker key={index} position={getMapLocation(orderList, item, mapLocations)}
                                    onClick={event => markerClickHandler(event, item)}
                                    icon={ item.shippingModeId == 1 ? airIcon : (item.shippingModeId == 2 ? lclIcon : fclIcon) }
                                    onLoad={marker => {
                                        return markerLoadHandler(marker, item)
                                    }}
                                />
                                :
                                <></>
                        ))}

                        {infoOpen && (
                            <InfoWindow
                                anchor={markerMap[selectedPlace.id]}
                                onCloseClick={() => setInfoOpen(false)}
                                headerContent={selectedPlace.jobNumber}
                            >
                                <div>
                                    <h3 style={{margin:"10px"}}>{selectedPlace.jobNumber}</h3>
                                    <a style={{ cursor: "pointer", radius: "1px solid #0c65c1", color: "#0c65c1", margin: "10px", textDecoration: "underline" }} onClick={() => orderDetailHandle(selectedPlace, TABS[1])}>
                                        {'More Details...'}
                                    </a>
                                    <div style={{ margin: "10px" }}>Shipper: {selectedPlace.shipper.companyName}</div>
                                    {/*<div style={{ height: "30px" }}>Type: {selectedPlace.shippingModeId == 1 ? "AIR" : (selectedPlace.shippingModeId == 2 ? "LCL" : "FCL")}</div>*/}
                                </div>
                            </InfoWindow>
                        )}
                    </GoogleMap>}
                </Grid>}
                <Grid md={4} item container alignItems="center" style={{ fontWeight: "bold", display: "none" }}>
                    <Grid item container justifyContent="center" alignItems="center">
                        <Grid item md={9} container> Shipper In Process </Grid>
                        <Grid item md={1} container> : {shipperItems} </Grid>
                    </Grid>
                    <Grid item container justifyContent="center" alignItems="center">
                        <Grid item md={9} container> Shipper completed, trucker in process </Grid>
                        <Grid item md={1} container> : {shipperInitItems} </Grid>
                    </Grid>
                    <Grid item container justifyContent="center" alignItems="center">
                        <Grid item md={9} container> Trucker completed, departure in process </Grid>
                        <Grid item md={1} container> : {truckerItems} </Grid>
                    </Grid>
                    <Grid item container justifyContent="center" alignItems="center">
                        <Grid item md={9} container> Departure completed, POD in process </Grid>
                        <Grid item md={1} container> : {departureItems} </Grid>
                    </Grid>
                    <Grid item container justifyContent="center" alignItems="center">
                        <Grid item md={9} container> POD completed, customs in process </Grid>
                        <Grid item md={1} container> : {podItems} </Grid>
                    </Grid>
                    <Grid item container justifyContent="center" alignItems="center">
                        <Grid item md={9} container> Customs completed, inporter in process </Grid>
                        <Grid item md={1} container> : {customsItems} </Grid>
                    </Grid>
                    <Grid item container justifyContent="center" alignItems="center">
                        <Grid item md={9} container> Importer completed </Grid>
                        <Grid item md={1} container> : {importedItems} </Grid>
                    </Grid>
                </Grid>
            </Grid>}
            <Box className="dashboard-content" style={{ height: `${mapDisplay ? "57vh" : "90vh"}` }}>

                {isShipmentList ?
                    <Box className="active-shipments">
                        {isLoading && <Grid item container justifyContent="center">
                            <CircularProgress style={{ marginTop: "20px" }} />
                        </Grid>}
                        {(!isLoading && isImportInList(orderL) > 0) && <TitleBar titleName={'Import'}>
                            {activeShipmentsActions()}
                        </TitleBar>}
                        {(!isLoading && isImportInList(orderL) > 0) && <ShipmentDataRow orders={orderL} isLoading={isLoading}
                            showImportsExport={true}
                            showImports={true}
                            polAir={polAir}
                            podAir={podAir}
                            polSea={polSea}
                            podSea={podSea} cOrigin={cOrigin} setisShipmentList={setisShipmentList} orderDetailHandle={orderDetailHandle} />}
                        {(!isLoading && isExportInList(orderL) > 0) && <TitleBar titleName={'Export'}>
                            {activeShipmentsActionsExport()}
                        </TitleBar>}
                        {(!isLoading && isExportInList(orderL) > 0) && <ShipmentDataRow orders={orderL} isLoading={isLoading}
                            showImportsExport={true}
                            showImports={false}
                            polAir={polAir}
                            podAir={podAir}
                            polSea={polSea}
                            podSea={podSea} cOrigin={cOrigin} setisShipmentList={setisShipmentList} orderDetailHandle={orderDetailHandle} />}
                    </Box> : <ShipmentDataMap orders={orderL}
                        polAir={polAir}
                        podAir={podAir}
                        polSea={polSea}
                        podSea={podSea} cOrigin={cOrigin} setisShipmentList={setisShipmentList} orderDetailHandle={orderDetailHandle} />
                }
                
            </Box>
            {/* Shipment Details Dialog */}
            <ShipmentDetailDialog
                setOrderList={setOrderList1}
                orderList={orderList}
                loginUser={loginUser}
                open={isOrderDetails}
                close={setIsOrderDetails}
                order={selectedOrder}
                defaultTab={activeTab}
                polAir={polAir}
                podAir={podAir}
                polSea={polSea}
                podSea={podSea}
                sMode={sMode}
                cOrigin={cOrigin}
            />
        </Fragment>
    )
};

export default CustomerDashboard;