import { Fragment, useCallback, useState, useEffect } from 'react';

import { Grid, Typography, Button, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Tooltip } from 'style-guide';

import { REQUEST_ACTIONS, sendRequest } from 'utils/Communicator';

import { useDispatch, useSelector } from 'react-redux';
import { snackbarToggle } from 'reducers/snackbarSlicer';

const FilesRender = ({ orderId, files, codeFileCount, loginUser }) => {
    const dispatch = useDispatch();
    const { fileCodes } = useSelector(state => state.predefinedData);

    const [openFiles, setOpenFiles] = useState(false);
    const [filesByCode, setFilesByCode] = useState(files);
    const [countByFileCode, setCountByFileCode] = useState(codeFileCount);

    const hostName = window.location.protocol + '//' + window.location.host;
    const btnTexts = [
        "AGENT RATE",
        "Commercial Invoice",
        "CUSTOM DECLARATION",
        "D/N",
        "CUSTOMER DOC",
        "GENERAL",
        "HAWB",
        "INVOICE /PACKING LIST",
        "MAWB",
        "PERMITS",
        "PACKING LIST",
        "QUOTATION",
        "EURO 1",
        "MSDS",
        "PICTURES",
        "DGD/IMO",
        "shipper docs",
        "cargo receipt",
        "BOOKING CONFIRMATION",
        "Customer Invoice"];
    useEffect(() => {
        setFilesByCode(files);
        setCountByFileCode(codeFileCount);
    }, [files, codeFileCount]);

    const onHandleFilesClose = () => setOpenFiles(false);

    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);

    const onHandleFilesOpen = code => {
        const currentCodeId = fileCodes.filter(codes => codes.name === code)[0].id;
        if (orderId) {
            sendRequest(`/api/${orderId}/files/${currentCodeId}`, REQUEST_ACTIONS.GET, '', {
                successCallback: response => {
                    setFilesByCode(response);
                    setOpenFiles(true);
                },
                failedCallback,
            });
        } else {
            setFilesByCode(files.filter(file => file.codeId === currentCodeId));
            setOpenFiles(true);
        }
    };
    const onRemoveFile = (file, orderId) => {
        for (var i = 0; i < files.length; i++) {
            if (files[i].id === file.id) {
                if (window.confirm('Are you sure you want to delete this file: ' + file.name + '?')) {
                    for (var j = 0; j < codeFileCount?.length; j++) {
                        codeFileCount = [...codeFileCount];
                        if (codeFileCount[j].code === btnTexts[file.codeId - 1]) {
                            if (codeFileCount[j].count > 1) {
                                codeFileCount.splice(j, 1, { "code": codeFileCount[j].code, "count": codeFileCount[j].count - 1 });
                                setCountByFileCode(codeFileCount);
                            }
                            else {
                                codeFileCount.splice(j, 1);
                                setCountByFileCode(codeFileCount);
                            }
                            break;
                        }
                    }

                    if (orderId) {
                        files = [...files];
                        console.log("file");
                        console.log(file);
                        sendRequest(`/api/files/${file.id}/${loginUser.id}/${orderId}/${file.name}`, REQUEST_ACTIONS.DELETE, '', {
                            successCallback: (message) => {
                                for (var p = 0; p < files.length; p++) {
                                    if (files[p].id === file.id) {
                                        files.splice(p, 1);
                                    }
                                }
                                setFilesByCode(files.filter(currentFile => currentFile.codeId === file.codeId));
                                //setFilesByCode(files);
                                dispatch(snackbarToggle({ type: 'success', message }));
                            },
                            failedCallback,
                        });
                    } else {
                        for (var p = 0; p < files.length; p++) {
                            if (files[p].id === file.id) {
                                files.splice(p, 1);
                            }
                        }
                        setFilesByCode(files.filter(currentFile => currentFile.codeId === file.codeId));
                        //setFilesByCode(files);
                    }
                } else {
                    // Do nothing!
                }
            }
        }
    }

    return !openFiles && countByFileCode?.length ? (
        <Grid container item spacing={2} maxHeight='200px' overflow='auto'>
            {countByFileCode.map(({ code, count }) => (
                <Grid item key={code} xs={12} md={6} lg={4}>
                    <Button size='small' variant='text' onClick={() => onHandleFilesOpen(code)}>
                        <Typography noWrap variant='subtitle2'>
                            {code} ({count})
                        </Typography>
                    </Button>
                </Grid>
            ))}
        </Grid>
    ) : (
        <Fragment>
            {!!countByFileCode?.length && (
                <Grid item xs={12}>
                    <IconButton size='small' color='primary' onClick={onHandleFilesClose}>
                        <ArrowBackIcon />
                    </IconButton>
                </Grid>
            )}
            <Grid container item spacing={2} maxHeight='100px' overflow='auto'>
                {filesByCode.map(file => {
                    return (
                        <Grid item key={file.id} xs={4}>
                            <Button size='small' onClick={() => onRemoveFile(file, orderId)} style={{ color: 'red', fontSize: '20px' }}>X</Button>
                            <Tooltip title={orderId ? 'Download the file: ' + file.name : 'At first save order please'}>
                                <Button variant='text' href={orderId ? `${hostName + file.uri}` : null}>
                                    <Typography noWrap variant='subtitle2' maxWidth='200px'>
                                        {file.name}
                                    </Typography>
                                </Button>
                            </Tooltip>
                        </Grid>
                    );
                })}
            </Grid>
        </Fragment>
    );
};

export default FilesRender;
