import { useCallback, useEffect, useState } from 'react';

import { Box, Grid, Button, CircularProgress, Table, TableBody, TableRow, TableCell, Typography } from '@mui/material';
import { sendRequest, REQUEST_ACTIONS, setOrderData } from 'utils/Communicator';
import { snackbarToggle } from "reducers/snackbarSlicer";
import { useDispatch, useSelector } from 'react-redux';
import DoneIcon from '@mui/icons-material/Done';
import ChatDialog from './ChatDialog';
import { Dialog } from 'style-guide';
import OrderForm from './OrderForm';
import { styled } from '@mui/system';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
    onSearchChange,
    resetOrderTableFilterSlice,
} from 'reducers/order-reducers/orderTableFilterSlicer';
import {
    onSelectedLocationIndexChange,
} from 'reducers/order-reducers/orderTableFilterSlicer';
import { getOrders, onChatListChange, onOrdersCountChange, onChatCountChange, onChatCountTChange, onChatCountCChange, onChatCountCTChange, onUserSpecificOrdersCountChange, onPageChange, onUnConfirmedOrdersCountChange } from 'reducers/order-reducers/ordersSlicer';
import { addOrderFilter, addSearchFilter, StyledTableCell, advancedSearchSelectedStyle, advancedSearchDefaultStyle, usePrevious } from 'Utils';

const ChatListDialog = ({ loginUser, setSearchText, onHandleCancelChatNotificationDialog, filterSpinner, setFilterSpinner }) => {
    const dispatch = useDispatch();
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const [chatListSpinner, setChatListSpinner] = useState(false);
    //const [chatList, setChatList] = useState([]);
    const [currentChat, setCurrentChat] = useState(null);
    const [currentOrder, setCurrentOrder] = useState(null);
    const [openChatDialog, setOpenChatDialog] = useState(false);
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onOrderModalHandleOpen = () => setOpenOrderModal(true);
    const onOrderModalHandleCancel = () => setOpenOrderModal(false);

    const { chatListNotification } = useSelector(state => state.orders);

    const {
        userLocalNumber,
        userAbroadNumber,
        consigneeId,
        shipperId,
        countryOfOriginId, originState,
        shippingModeId,
        subModeId,
        userLocalId,
        userTracer,
        userCustoms,
        userAbroadId,
        dueDate,
        lastUpdateDate,
        ilReminderDate,
        reminderDate,
        selectedLocationIndex,
        search,
        startReminderDate,
        endReminderDate,
        startIlReminderDate,
        endIlReminderDate,
        stageSearch,
        stageStateSearch,
        reminderDateAdv, ilReminderDateAdv, } = useSelector(state => state.orderTableFilter);
    const orderTableFilter = {
        userLocalNumber,
        userAbroadNumber,
        consigneeId,
        shipperId,
        countryOfOriginId, originState,
        shippingModeId,
        subModeId,
        userLocalId,
        userTracer,
        userCustoms,
        userAbroadId,
        dueDate,
        lastUpdateDate,
        ilReminderDate,
        reminderDate,
        selectedLocationIndex,
        search,
        startReminderDate,
        endReminderDate,
        startIlReminderDate,
        endIlReminderDate,
        stageSearch,
        stageStateSearch,
        reminderDateAdv, ilReminderDateAdv,
    };

    const onChatDialogHandleOpen = () => setOpenChatDialog(true);
    const onChatDialogHandleClose = () => {
        /*setChatListSpinner(true);
          sendRequest(
            `/api/chat/list?${addOrderFilter(loginUser, '', selectedLocationIndex)}`,
            REQUEST_ACTIONS.GET,
            '',
            {
              successCallback: response => {
                setChatList(response);
                setChatListSpinner(false);
              },
              failedCallback,
            },
          );*/
        setOpenChatDialog(false);
    }

    const StyledContent = styled('span')`
    cursor: pointer;
    text-decoration: underline;`;

    useEffect(() => {
        /*setChatListSpinner(true);
        /*sendRequest(
          `/api/chat/list?${addOrderFilter(loginUser, '', selectedLocationIndex)}`,
          REQUEST_ACTIONS.GET,
          '',
          {
            successCallback: response => {
              setChatList(response);
              setChatListSpinner(false);
            },
            failedCallback,
          },
        );*/
    }, []);

    const openChatDialogByOrderID = (order) => {
        if (order?.orderId) {
            sendRequest('/api/order/' + order.orderId, REQUEST_ACTIONS.GET, '', {
                successCallback: response => {
                    setCurrentOrder(response);
                    setCurrentChat(order);
                    onChatDialogHandleOpen();
                    //onHandleCancelChatNotificationDialog();
                },
                failedCallback,
            });
        }
    }

    const openOrderDialogByOrderID = orderId => {
        if (orderId) {
            sendRequest('/api/order/' + orderId, REQUEST_ACTIONS.GET, '', {
                successCallback: response => {
                    setCurrentOrder(response);
                    onOrderModalHandleOpen();
                    //onHandleCancelChatNotificationDialog();
                },
                failedCallback,
            });
        }
    }
    const getLocationFromId = (id) => {
        if (Number(id) == 1) {
            return "NL";
        } else if (Number(id) == 2) {
            return "EXP";
        } else if (Number(id) == 4) {
            return "US";
        } else if (Number(id) == 5) {
            return "ALL"
        }
    }
    const openOrder = (co) => {
        var selLocation = selectedLocationIndex;
        /*setVarInterval(setInterval(() => {
            clearInterval(varInterval);
            dispatch(onSelectedLocationIndexChange(3));
            dispatch(onSearchChange(data.jobNumber));
        }, 1000));*/
        var data = {
            jobNumber: co.jobNumber,
            location: co.orderServiceLocation
        }
        if (data.location === "IL") {
            if (selectedLocationIndex != 3) {
                dispatch(onSelectedLocationIndexChange(3));
                dispatch(onSearchChange(data.jobNumber));
                selLocation = 3;
            }
        }
        else if (data.location === "NL") {
            if (selectedLocationIndex != 0) {
                dispatch(onSelectedLocationIndexChange(0));
                dispatch(onSearchChange(data.jobNumber));
                selLocation = 0;
            }
        }
        else if (data.location === "US") {
            if (selectedLocationIndex != 1) {
                dispatch(onSelectedLocationIndexChange(1));
                dispatch(onSearchChange(data.jobNumber));
                selLocation = 1;
            }
        }
        else if (data.location === "ALL") {
            if (selectedLocationIndex != 2) {
                dispatch(onSelectedLocationIndexChange(2));
                dispatch(onSearchChange(data.jobNumber));
                selLocation = 2;
            }
        }
        var dt = [];
        dt.push(data);
        var pageName = "Order";
        //setSearchSpinner(true);
        dispatch(resetOrderTableFilterSlice(null));
        dispatch(onSearchChange(data.jobNumber));
        setSearchText(data.jobNumber);
        var qp = ""
        if (loginUser.department) {
            qp += "&userVisibility=" + loginUser.department;
        } else
            qp += "&userVisibility=1";
        sendRequest("/api/order?showUnassignedOrders=true&dep=1&page=0&size=1&search=" + data.jobNumber + qp, REQUEST_ACTIONS.GET, '', {
            successCallback: response => {
                setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
                onHandleCancelChatNotificationDialog();
            },
            failedCallback,
        });
    }

    const seenChat = (ch) => {
        for (var i = 0; i < chatListNotification.length; i++) {

        }
        console.log(ch);

        if (loginUser.authority != "Admin") {
            sendRequest(`/api/fa/seenChat/${ch.orderId}/${loginUser.id}/${ch.chatOrigin}/${ch.chatTime}`, REQUEST_ACTIONS.GET, '', {
                successCallback: response => {
                    sendRequest(
                        `/api/chat/list?${addOrderFilter(loginUser, '', selectedLocationIndex)}`,
                        REQUEST_ACTIONS.GET,
                        '',
                        {
                            successCallback: response => {
                                dispatch(onChatListChange(response))
                            },
                            failedCallback,
                        },
                    );
                },
                failedCallback,
            });
        }
    }
    return (
        <Grid>
            <div style={{ 'max-height': '60vh', overflowY: 'auto' }}>
                <Grid item container
                    sx={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}>
                    {chatListNotification?.length > 0 ? chatListNotification?.map((ch, index) => (
                        <Grid key={index} item container alignItems="center"><span onClick={() => { seenChat(ch) }} style={{ width: "20px", marginLeft:"0", marginRight: "20px", cursor: "pointer" }}><DoneIcon/></span><Button display="flex" flexWrap="wrap" sx={{ width: "calc(100% - 60px)", cursor: 'default', mr: 2, my: 0.5, border: '1px solid black', color: 'black', justifyContent: 'left', textAlign: 'left' }} style={{ textTransform: 'none' }}>
                                {ch.chatTime ? new Intl.DateTimeFormat('en-GB', {
                                    year: '2-digit',
                                    month: '2-digit',
                                    day: '2-digit',
                                }).format(new Date(ch.chatTime)) + ' ' + new Intl.DateTimeFormat('en-GB', {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                }).format(new Date(ch.chatTime)) : ''}{' ('} {/*<ContentCopyIcon title="Copy Job Number" onClick={() => {
                      navigator.clipboard.writeText(ch.jobNumber);
                      alert("Copied: " + ch.jobNumber);
                  }} style={{ marginBottom: "-7px", cursor: "pointer" }} /> */} <StyledContent onClick={() => {
                                    /*openOrderDialogByOrderID(ch.orderId)*/
                                    openOrder(ch)
                                }}>{ch.jobNumber + ' ' + (ch.orderServiceLocation || '-')}</StyledContent> <span style={{ 'margin-right': '3px' }}> {') : ' + ch.chatInitiator + ' : '} </span> <StyledContent onClick={() => openChatDialogByOrderID(ch)}>{ch.chatText}</StyledContent>
                        </Button> </Grid>
                        )) : 'No New Chat Notification'
                    }
                    {openChatDialog && (
                        <Dialog
                            open={openChatDialog}
                            onHandleCancel={onChatDialogHandleClose}
                            title={'Chat Box - ' + currentOrder?.jobNumber}
                            maxWidth='md'>
                            <ChatDialog
                                filterSpinner={filterSpinner} setFilterSpinner={setFilterSpinner}
                                consigneeNameDisplay={currentOrder?.consignee?.companyShortName ? currentOrder?.consignee?.companyShortName : (currentOrder?.consignee?.companyName || '-')}
                                shipperNameDisplay={currentOrder?.shipper?.companyShortName ? currentOrder?.shipper?.companyShortName : (currentOrder?.shipper?.companyName || '-')}
                                onHandleCancel={onChatDialogHandleClose} order={currentOrder} loginUser={loginUser} chatOrigin={Number(currentChat.chatOrigin)} />
                        </Dialog>
                    )}
                    {openOrderModal && (
                        <Dialog
                            open={openOrderModal}
                            onHandleCancel={onOrderModalHandleCancel}
                            title={'Order information - ' + currentOrder?.jobNumber}
                            maxWidth='md'>
                            <OrderForm loginUser={loginUser} onHandleCancel={onOrderModalHandleCancel} order={currentOrder} orderTableFilter={orderTableFilter} pageName='Chat List Dialog' />
                        </Dialog>
                    )}
                </Grid>
            </div>
        </Grid>
    );
};

export default ChatListDialog;
