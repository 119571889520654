import { Fragment, useCallback, useEffect, useState, useRef } from 'react';

import { Box, Grid, Button, CircularProgress } from '@mui/material';
import { MultilineField, TextField } from 'style-guide';
import { sendRequest, REQUEST_ACTIONS, setOrderData, updateOrder } from 'utils/Communicator';
import { sendNewChatMsgNotification, sendNewOrderNotification } from 'utils/Notifications';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarToggle } from 'reducers/snackbarSlicer';
import { onCustChatListChange, getOrders, onOrdersCountChange, onChatCountChange, onChatCountCChange, onChatCountTChange, onChatCountCTChange, onUserSpecificOrdersCountChange, onUnConfirmedOrdersCountChange, onPageChange } from 'reducers/order-reducers/ordersSlicer';
import { addOrderFilter, addSearchFilter, usePrevious } from 'Utils';
import CheckBoxIcon from '@mui/icons-material/CheckBoxOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const ilChatNameStyle = { color: 'blue' };
const nlChatNameStyle = { color: 'orange' };
const adChatNameStyle = { color: 'black' };

import {
    onUserUSDataChange,
    onUserNLDataChange,
    onUserILDataChange,
} from 'reducers/order-reducers/predefinedDataSlicer';

var custChatTimeout = null;

const CustChatDialog = ({ onHandleCancel, order, loginUser, pageName }) => {

    const [state, setState] = useState();
    const [chatText, setChatText] = useState("");
    const [chatList, setChatList] = useState(JSON.parse(order['chatCustomer'] ? order['chatCustomer'] : '[]'));
    const [isLoaded, setIsLoaded] = useState(false);
    const [ilUsers, setIlUsers] = useState();
    const [nlUsers, setNlUsers] = useState();
    const [usUsers, setUSUsers] = useState();
    const [ilUsersLoaded, setIlUsersLoaded] = useState(false);
    const [nlUsersLoaded, setNlUsersLoaded] = useState(false);
    const [usUsersLoaded, setUSUsersLoaded] = useState(false);
    const [updatedChatView, setUpdatedChatView] = useState(false);
    const [varInterval, setVarInterval] = useState();
    const { page, size, chatCount, userSpecificOrdersCount, orders } = useSelector(state => state.orders);
    const prevPage = usePrevious({ page });

    const { userILData, userNLData, userUSData } = useSelector(state => state.predefinedData);
    //var messagesEnd;

    const dispatch = useDispatch();
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const failedCallback1 = useCallback(message => { }, [dispatch]);
    const mounted = useRef(false);

    const updateSeen = () => {
        return;
    };

    const setChatListFx = (chat) => {
        setChatList(chat);
        updateSeen();
    }
    useEffect(() => {
        return () => {
            if (order.id)
                sendRequest('/api/order/' + order.id, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {
                        var res = JSON.parse(JSON.stringify(response))
                        if (order.astatus)
                            res.astatus = JSON.parse(JSON.stringify(order.astatus));
                        if (order.cstatus)
                            res.cstatus = JSON.parse(JSON.stringify(order.cstatus));
                        updateOrder(orders, res, getOrders, dispatch);
                        //updateOrder(orders, response, getOrders, dispatch);
                    },
                    failedCallback,
                });
        }
    }, []);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        }
    }, []);

    useEffect(() => {
        if (!isLoaded) {
            custChatTimeout = setInterval(() => {
                sendRequest('/api/order/' + order['id'], REQUEST_ACTIONS.GET, '', {
                    successCallback: response => { setChatListFx(JSON.parse(response['chatCustomer'])); },
                    failedCallback1,
                });
            }, 15000);
            setIsLoaded(true);
        }
        return () => {
            clearInterval(custChatTimeout);
        }
    }, []);

    useEffect(() => {
        // predefined user abroad data get
        if (!userNLData) {
            sendRequest('/api/users/NL', REQUEST_ACTIONS.GET, '', {
                loginUser: loginUser,
                successCallback: response => {
                    onUserNLDataChange(response);
                    setNlUsers(response);
                    setNlUsersLoaded(true)
                },
                failedCallback,
            });
        } else {
            setNlUsers(userNLData);
        }
    }, [userNLData]);

    useEffect(() => {
        // predefined user abroad data get
        if (!userUSData) {
            sendRequest('/api/users/US', REQUEST_ACTIONS.GET, '', {
                loginUser: loginUser,
                successCallback: response => {
                    onUserUSDataChange(response);
                    setUSUsers(response);
                    setUSUsersLoaded(true)
                },
                failedCallback,
            });
        } else {
            setUSUsers(userUSData);
        }
    }, [userUSData]);

    useEffect(() => {
        // predefined user local data get
        if (!userILData) {
            sendRequest('/api/users/IL', REQUEST_ACTIONS.GET, '', {
                loginUser: loginUser,
                successCallback: response => {
                    onUserILDataChange(response);
                    setIlUsers(response);
                    setIlUsersLoaded(true)
                },
                failedCallback,
            });
        } else {
            setIlUsers(userILData);
        }
    }, [userILData]);




    useEffect(() => {
        // for construction
        if (chatList?.length != 0) {
            updateSeen();
            var needReview = false;
            for (var i = 0; i < chatList?.length; i++) {
                if (!chatList['ILStatus']) {
                    needReview = true;
                }
            }
            setUpdatedChatView(needReview);
        }
    }, [ chatList]);


    const setChatListFx1 = (chat) => {
        setChatList(chat);
        setChatText("");
        setUpdatedChatView(true);
    }

    const onHandleSendChat = (statusChatButton) => {
        if (order.orderStage[0].orderConfirmationStage.length == 0) {
            //dispatch(snackbarToggle({ type: 'error', message: 'Order Conformation should be completed before chat can ne used.' }))
            // return;
        }
        if (chatText == "") {
            dispatch(snackbarToggle({ type: 'error', message: 'Text is empty.' }))
            return;
        }
        var userIL = order.userILId;
        var userNL = order.orderStage[0].orderConfirmationStage[0]?.userNLId;
        var lengthIL = ilUsers.length;
        var lengthNL = nlUsers.length;
        var lengthUS = usUsers.length;
        var exists = false;
        var date = new Date();
        var chat = {
            'time': date.toUTCString(),
            'text': chatText,
            'initiator': loginUser.fullName,
            'initiatorType': 2,
            'location': '',
            'ILStatus': true,
            'NLStatus': false
        }
        let o1 = { ...order }

        var chatString = JSON.stringify(chat);

        onUpdateChat(order.id, chatString, 0);
        return;
    }
    const onHandlechangeChat = (value) => {
        setChatText(value);
    }

    const setChatListFx2 = (chat) => {
        setChatList(chat);
        setChatText("");
    }
    const onUpdateChat = (id, chatString, fromStage) => {
        var data = {
            value: chatString
        }
        sendRequest(`/api/order/updateCustChat/${id}?fromStage=${fromStage}&loginUserId=${loginUser?.id}&actionOn=ChatDialog`, REQUEST_ACTIONS.PUT, data, {
            successCallback: data => {
                setChatListFx2(JSON.parse(JSON.stringify([...chatList, JSON.parse(chatString)])));
            },
            failedCallback,
        });
    };
    const getInitiatorStyle = (ch) => {
        var type = ch['initiatorType']
        if (type == 5)
            return " " + nlChatNameStyle;
        else
            return " " + ilChatNameStyle;
    }

    const reviewChat = (index) => {
        var chList = [];
        var changeMade = true;
        for (var i = 0; i < chatList.length; i++) {
            var chat = JSON.parse(JSON.stringify(chatList[i]));
            chat['ILStatus'] = true;
            chList.push(chat);
        }

        let o1 = { ...order }
        if (changeMade) {
            o1.chatCustomer = JSON.stringify(chList)
            sendRequest('/api/order?fromStage=0&loginUserId=' + loginUser?.id + '&actionOn=ChatSeen', REQUEST_ACTIONS.POST, o1, {
                successCallback: response => {
                    setChatList(JSON.parse(o1.chatCustomer));
                    setChatListFx1(JSON.parse(o1.chatCustomer));
                },
                failedCallback,
            });
        }
    }
    const allChatViewed = () => {
        for (var i = 0; i < chatList.length; i++) {
            if (!chatList['ILStatus'])
                return true;
        }
        return false;
    }
    const getChatDate = (ch) => {
        return ch.time ? new Intl.DateTimeFormat('en-GB', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
        }).format(new Date(ch.time)) + ' ' + new Intl.DateTimeFormat('en-GB', {
            hour: '2-digit',
            minute: '2-digit',
        }).format(new Date(ch.time)) : '';
    }
    return (
        <Box component='form' noValidate autoComplete='off'>
            <Grid container spacing={2} position='relative'>
                <Grid item container style={{ marginBottom: "15px" }} flexWrap="nowrap">
                    <Grid item container style={{ margin: "10px" }}>
                        <ContentCopyIcon title="copy" onClick={() => copyText(order?.jobNumber)} />
                        {order?.jobNumber}
                    </Grid>
                    <Grid item container style={{ margin: "10px" }}>
                        {/*<ContentCopyIcon title="copy" onClick={() => copyText(consigneeNameDisplay)} />*/}
                        {order?.consignee?.companyName}
                    </Grid>
                    <Grid item container style={{ margin: "10px" }}>
                        {/*<ContentCopyIcon title="copy" onClick={() => copyText(shipperNameDisplay) } /> */}
                        {order?.shipper?.companyName}
                    </Grid>
                </Grid>

                <Fragment>
                    <div style={{ height: '60vh', width: '900px', overflowY: 'auto', border: '1px solid black', display: 'flex', flexDirection: 'column-reverse' }}>
                        <div style={{ height: 'auto', width: '100%' }}>
                            {chatList?.map((ch, index) => (
                                <span key={index} style={{ width: '100%', padding: '5px' }}>
                                    <span style={{ color: 'grey' }}>{getChatDate(ch)}</span>&nbsp;
                                    <span style={{ color: `${ch['initiatorType'] == 5 ? "orange" : "blue"}` }}>{ch['initiator']}</span>
                                    <span style={{ color: 'black' }}> : {ch['text']}</span><br />
                                </span>
                            ))}
                        </div>
                        <div style={{ float: "left", clear: "both" }} id="bottomOfScreen"></div>
                    </div>
                    <Grid container item justifyContent='space-between' flexWrap="nowrap" alignItems="flex-end">
                        <MultilineField
                            sm={10}
                            md={null}
                            lg={null} style={{ width: '100%' }} id="chatText" label="Chat" variant="outlined" type="text" value={chatText} onChange={(event) => onHandlechangeChat(event.target.value)}
                        />
                        <Button variant='contained' style={{marginRight:"16px", marginLeft:"16px", maxHeight:"37px"}} onClick={(event) => onHandleSendChat(11)}>
                            Send
                        </Button>
                        {/*{updatedChatView && <Button variant='contained' sx={{ mr: 2 }} onClick={(event) => reviewChat()}>
                            Review
                        </Button>}*/}
                        {<Button variant='contained' style={{ marginRight: "16px", maxHeight: "37px" }} onClick={(event) => reviewChat()}>
                            Review
                        </Button>}
                    </Grid>
                </Fragment>

            </Grid>
        </Box>
    );
};

export default CustChatDialog;
